import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomTextField } from "./CustomTextField";
import { API } from "../../api-services";

interface TableRowData {
  id: number;
  name: string;
  alias: string;
  constant: number | null;
  user_input: string | null;
  formula: string | null;
  sequence: number;
  test_input: number;
  lock: boolean;
  result: number;
  content_type: string | null;
  parameter: string | null;
  value: number | null;
  unit: string;
}

interface Props {
  data: TableRowData[];
  inputData: any;
  getData: any;
  type: any;
}

const DatabasecalcTable: React.FC<Props> = ({
  data,
  inputData,
  getData,
  type,
}) => {
  const [userInputUpdated, setUserInputUpdated] = React.useState<number>(0);

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: { lg: "45vh", xl: "60vh" },
      }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead sx={{ backgroundColor: "primary.light" }}>
          <TableRow>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                backgroundColor: "primary.light",
                width: "40%",
              }}
            >
              <strong>Input</strong>
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                backgroundColor: "primary.light",
                width: "40%",
              }}
            >
              <strong>Value</strong>
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                backgroundColor: "primary.light",
                width: "20%",
              }}
            >
              <strong>Unit</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                {row.name}
              </TableCell>
              <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                {row.formula === null &&
                row.constant === null &&
                row.user_input ? (
                  <CustomTextField
                    row={row}
                    setUserInputUpdated={setUserInputUpdated}
                    inputData={inputData && inputData}
                    getData={getData}
                    type={type}
                  />
                ) : (
                  row.value
                )}
              </TableCell>
              <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                {row.unit}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box></Box>
    </Box>
  );
};

export default DatabasecalcTable;
