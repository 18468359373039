// react
import Icon from "@mui/material/Icon";
import React, { useState } from "react";
import { ADMIN } from "../../Redux/Services/admin.service";
import landingPageService from "../../Redux/Services/landingPage.service";
import SquareIcon from "@mui/icons-material/Square";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "./LoginInfo.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";
import notificationImg from "../../Assets/images/notification.svg";
import UserAlertsModal from "./UserAlertsModal";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { fontSize } from "@mui/system";
import swal from "sweetalert";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
// interfaces and types
// lazy
// ----------------------------------------------------------------------------------
interface LoginInfoProps {
  alertsData: any;
  itemId: any;
  desc: any;
  created: any;
  description: any;
  Handler: any;
  UnReadHandler: any;
  ModuleName: any;
  notifiactionItemStatus: any;
  FetchNotification: any;
}
const LoginInfo: React.FC<LoginInfoProps> = (props) => {
  const {
    alertsData,
    itemId,
    Handler,
    description,
    created,
    ModuleName,
    UnReadHandler,
    notifiactionItemStatus,
    FetchNotification,
  } = props;
  const [alerts, setAlerts] = useState<any>(alertsData);
  const [userChecked, setUserChecked] = useState(false);
  const [alertData, setAlertData] = useState<any>();
  const [idData, setIdData] = useState<any>();
  const [dropdownData, setDropdownData] = useState("");
  const [sendMail, setSendMail] = useState(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [actionColor, setActioncolor] = useState<any>("blue");
  const loginmodal = useBaseModal();
  const [DateData, setDateData] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [Phonechecked, setPhoneChecked] = React.useState(false);
  const [Emailchecked, setEmailchecked] = React.useState(true);

  const first = description.startsWith("Guest User");
  //     const handleChange = ( ed: any ) => {
  // console.log(ed,"esdsddsd")
  //         if ( ed.name === "dateData" ) {
  //             setActioncolor( "red" );
  //             setAlertData( ed?.value );
  //         } else if ( ed.name === "dropdown" ) {
  //             setDropdownData( ed?.value );
  //             setActioncolor( "red" );
  //         }
  //     };
  //   const handleChange = (event: SelectChangeEvent) => {
  //     // setAge(event.target.value );
  //     console.log(event, "event");
  //     // setDropdownData( ev);
  //   };
  const handleChangeDate: any = (e: any) => {
    var date = new Date(e.target?.value);
    var dateto = moment(date).format("YYYY-MM-DD");
    setActioncolor("red");
    setDateData(dateto);
    //   setAlertData( ed?.value );
  };
  const handleChecked = (id: any) => {
    //  loginmodal
    API.post("/auth/user_request/", { id: id, check_action: true }).then(
      (res: any) => {
          
        setPhoneChecked(res?.data?.phone_otp)
        setEmailchecked(true)
        if (res?.data?.action == false) {
          loginmodal.open();
        } else {
          setIdData(id);
          setUserChecked(!userChecked);
        }
      }
    );
  };
  const submitAlerData = () => {
    if (dropdownData !== "") {



      swal({
        title: "Approve Access",
        text: "Are sure you want to accept request ?",
  
        buttons: ["Cancel", "Ok"],
        // icon: "sucess",
      }).then((confirm: any) => {
        if (confirm) {
          setUserChecked(false);
      landingPageService
        .userData(idData, true, DateData, dropdownData,Emailchecked,Phonechecked, sendMail)
        .then((res) => {
          if (res.status === 200) {
            // ADMIN.toast.success("User Accepted");
          }
        })
        .catch((err: any) => {
          // ADMIN.toast.throwError(err, "Server Error")
          setUserChecked(!userChecked);
        });
      const filterData = alerts.filter((item: any) => item.id !== idData);
      setAlerts(filterData);
      setSubmitted(true);
        }
      });




      // console.log("dropdownData", dropdownData);
     
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDropdownData(event.target.value as string);
    // setAlertData(event.target.value as string)
  };
  const operationOption = [
    {
      key: 1,
      value: "Project Administrator",
      text: "Project Administrator",
    },
    {
      key: 2,
      value: "Module Administrator",
      text: "Module Administrator",
    },
    {
      key: 3,
      value: "User",
      text: "User",
    },
    {
      key: 4,
      value: "Guest User",
      text: "Guest User",
    },
    {
      key: 5,
      value: "Unapproved User",
      text: "Unapproved User",
    },
  ];
  const operationOptionone = [
    {
      key: 1,
      value: "Guest User",
      text: "Guest User",
    },
  ];

  const getApicallhandler = () => {
    loginmodal.close();
    FetchNotification();
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, type: any) => {
    // setChecked(event.target.checked);
    if (type == "phone") {
      setPhoneChecked(event.target.checked)
    }
    else if (type == "email") {
      setEmailchecked(true)
    }


  };

  return (
    <>
      <UserAlertsModal
        isOpen={loginmodal.isOpen}
        onCloseModal={loginmodal.close}
        getApicallhandler={() => getApicallhandler()}
      />
      {!submitted && (
        <div data-testid="" className={styles.container}>
          <div className={styles.SingupData}>
            <a
              className={styles.Notification_Container_active}
            //   onClick={() =>
            //     NotificationHandler(notifiactionItem?.id)
            //   }
            >
              {/* <Avatar sx={{ backgroundColor: "#007fff !important",width:"2rem",height:"2rem",fontSize:"1rem" }}>{ModuleName}</Avatar> */}

              <div>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      border: "1px solid #007fff",
                      width: "fit-content",
                      padding: "0 0.5rem",
                      fontSize: "10px",
                    }}
                  >
                    {ModuleName}
                  </Box>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    {" "}
                    {created}
                  </Typography>
                </Box>
                <div className={styles.userNotifydes}>
                  <div style={{ fontSize: "0.75rem" }}>{description}</div>
                  {/* <div style={{ fontSize: "0.75rem", width: '5rem' }}> {created}</div> */}
                </div>
              </div>
              <div className={styles.UserNotify}>
                <div style={{ display: "flex", gap: "3px" }}>
                  {userChecked == false && (
                    <CheckCircleIcon
                      color="success"
                      sx={{ cursor: "pointer" }}
                      titleAccess="Approve"
                      onClick={() => handleChecked(itemId)}
                      className="usernotiIcon"
                    />
                  )}
                  <BlockIcon
                    color="error"
                    sx={{ cursor: "pointer" }}
                    titleAccess="Reject"
                    onClick={() => Handler()}
                    className="usernotiIcon"
                  />
                </div>
              </div>
            </a>
            {userChecked && (
              <div className={styles.expiredData}>
                <div className={styles.topArrrow}>
                  {" "}
                  <SquareIcon
                    sx={{ transform: "translateY(-50%) rotate(45deg)" }}
                  />
                </div>
                <Box
                  style={{
                    padding: "0.3rem",
                    cursor: "pointer",
                    height: "1rem",
                  }}
                // onClick={() => handleChecked(itemId)}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      textAlign: "end",
                      color: "#007fff",
                    }}
                  >
                    <CheckCircleIcon
                      color="success"
                      onClick={submitAlerData}
                      titleAccess="Approve"
                    />
                    <ClearIcon
                      color="error"
                      titleAccess="Close"
                      onClick={() => handleChecked(itemId)}
                      className={styles.clearIcon}
                    />
                  </div>
                </Box>
                <div>
                  <span className={styles.expiredTitle}>
                    Select Expiration Date
                  </span>
                  <div className={styles.expiredContainer}>
                    <input
                      type="date"
                      name="date"
                      // @ts-ignore

                      min={moment(new Date()).format("YYYY-MM-DD")}
                      defaultValue={DateData}
                      // @ts-ignore
                      onChange={handleChangeDate}
                      style={{
                        margin: "0 0 1rem",
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid #000",
                        background: "none",
                        padding: "5px",
                        color: "#007fff",
                      }}
                      disabled={first ? true : false}
                    // required
                    />
                    {first ? (
                      <>
                        {/* <CheckIcon color='success'
                          onClick={() => handleChecked(itemId)}
                          className="usernotiIcon"
                        /> */}
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <span style={{ fontSize: "0.75rem" }}>
                              Select User Type
                            </span>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={dropdownData}
                              label="dropdownData"
                              variant="standard"
                              size="small"
                              onChange={handleChange}
                              sx={{
                                "&.MuiInputBase-root .MuiInput-root .MuiSelect-root:before":
                                {
                                  borderBottom: "1px solid #007fff",
                                  fontSize: "0.9rem",
                                },
                              }}
                            >
                              {operationOptionone.map(
                                (name: any, index: any) => (
                                  <MenuItem
                                    sx={{ fontSize: "0.75rem" }}
                                    key={name.text}
                                    value={name.text}
                                  //   style={getStyles(name, personName, theme)}
                                  >
                                    {name.text}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label"> */}
                            <span style={{ fontSize: "0.75rem" }}>
                              Select User Type
                            </span>
                            {/* </InputLabel> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={dropdownData}
                              label="dropdownData"
                              variant="standard"
                              onChange={handleChange}
                              size="small"
                              sx={{ fontSize: "0.75rem" }}
                              inputProps={{
                                fontSize: "0.75rem",
                              }}
                            >
                              {operationOption.map((name: any, index: any) => (
                                <MenuItem
                                  sx={{ fontSize: "0.75rem" }}
                                  key={name.text}
                                  value={name.text}
                                //   style={getStyles(name, personName, theme)}
                                >
                                  {name.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </>
                    )}

                    {dropdownData == "" && (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "0.75rem",
                          padding: "0.2rem 0 0",
                        }}
                      >
                        *Please select user type
                      </Typography>
                    )}
                    <Box sx={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', alignItems:'center',columnGap:'10px',justifyContent:'flex-start'}}>
                      <Typography style={{ fontSize: "0.9rem" }}>OTP*</Typography>
                      <FormControlLabel
                        value="Phone"
                        sx={{ fontSize: "0.9rem" }}
                        control={
                          <Checkbox

                            checked={Phonechecked}
                            onChange={(event: any) => handleChangeCheckbox(event, "phone")}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            sx={{ "& .MuiSvgIcon-root": { color:'primary.main' } }}
                          />
                        }
                        label={<span style={{ fontSize: '0.9rem' }}>{"Phone"}</span>}

                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="Email"
                        sx={{ fontSize: "0.9rem" }}
                        control={
                          <Checkbox
                            checked={Emailchecked}
                            disabled={true}
                            onChange={(event: any) => handleChangeCheckbox(event, "email")}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            sx={{ "& .MuiSvgIcon-root": { color:'primary.main' } }}
                          />
                        }
                        label={<span style={{ fontSize: '0.9rem' }}>{"Email"}</span>}
                        labelPlacement="end"
                      />
                    </Box>

                    <Box sx={{display:'grid', gridTemplateColumns:'1fr', alignItems:'center',columnGap:'10px',justifyContent:'flex-start'}}>
                    <FormControlLabel
          control={
            <Checkbox
              id="acceptTerms"
              name="acceptTerms"
              color="primary"
              checked={sendMail}
              onChange={(e)=> {
                setSendMail(e.target.checked)
              }}
              
              sx={{ "& .MuiSvgIcon-root": { color:'primary.main' } }}
            />
          }
          label="Send welcome mail"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '0.8rem', // Set the font size here
            },
          }}
        />
                    </Box>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
// ----------------------------------------------------------------------------------
export default LoginInfo;
