import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import ChatLeftSection from './ChatLeftSection';
import ChatRightSection from './ChatRightSection';


export interface IChatLandingpageProps {
}

export default function ChatLandingpage(props: IChatLandingpageProps) {
    const theme = useTheme();
    const [isEmptyGroup,setIsEmptyGroup] = React.useState<any>(false);
    const [reCallgrooup,setReCallGroup] = React.useState<any>(0);
    const [expanded,setExpanded] = React.useState<any>(true);
    return (
        <Box sx={{
            height: '100vh',
            // @ts-ignore
            background: theme.palette.background.customBackground,
        }}>
            <Box sx={{ padding: '1rem', width: '100%', height: '96.2%', }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: expanded == true ? { lg: '25rem 1fr', xl: '22rem 1fr' } : {lg:'0 1fr', xl:'0 1fr'}, overflow: 'hidden', width: '100%', height: '100%', padding: '0rem',transition: 'all 0.4s ease', }}>
                    {/* Left Panel */}
                    <Box sx={{
                        height: '100%', overflow: 'auto',
                        // @ts-ignore
                        background: theme.palette.background.paper,
                        // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
                    }}>
                        {<ChatLeftSection setIsEmptyGroup={setIsEmptyGroup} reCallgrooup={reCallgrooup} setExpanded={setExpanded} expanded={expanded}/>}
                    </Box>
                    <Box sx={{ height: '100%', overflow: 'auto', flexGrow: 1, padding: '0.5rem', background: theme.palette.background.paper }}>
                        <ChatRightSection isEmptyGroup={isEmptyGroup} setReCallGroup={setReCallGroup} setExpanded={setExpanded} expanded={expanded}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}