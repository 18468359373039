import { useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import MyChatComponent from "./Chattab/MyChatComponent";
import OtherChatComponent from "./Chattab/OtherChatComponent";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { AUTH } from "../Redux/Services/auth.service";

type Group = {
  id: number;
  name: string;
  chats: Chat[];
};

type Chat = {
  chat_id: number;
  chat_name: string;
};

const ChatLeftSection: any = (props: any) => {
  const { setIsEmptyGroup, reCallgrooup, setExpanded, expanded, } = props;
  const { grpId, chatId, userId, tab } = useRouteParams<any>();
  const history = useHistory();
  const [active, setActive] = useState("mychat");

  const chageChatTab = (tab: string) => {
    setActive(tab);
    // :grpId/:chatId/:userId/:tab
    history.push(`/chat/${0}/${0}/${0}/${tab}`);
  };

  return (
    <Box
      sx={{
        padding: 1,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 1,
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Box sx={{ display: "flex", marginRight: "0.2rem" }}>
          {
            <AutoAwesomeMosaicIcon
              titleAccess="Collapse window"
              sx={{ fontSize: "2.5rem", cursor: "pointer" }}
              onClick={() => {
                setExpanded(false);
              }}
            />
          }
        </Box>
        {AUTH.isSuperUser && (
          <Box
            sx={{
              padding: "0.5rem 1rem",
              backgroundColor:
                tab === "mychat" ? "primary.main" : "primary.light",
              color: tab === "mychat" ? "white" : "primary.main",
              textAlign: "center",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => chageChatTab("mychat")}
          >
            My Chat
          </Box>
        )}
        {AUTH.isSuperUser && (
          <Box
            sx={{
              padding: "0.5rem 1rem",
              backgroundColor:
                tab === "otherchat" ? "primary.main" : "primary.light",
              color: tab === "otherchat" ? "white" : "primary.main",
              textAlign: "center",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => chageChatTab("otherchat")}
          >
            Other Chat
          </Box>
        )}
      </Box>
      {tab === "mychat" ? (
        <MyChatComponent
          setIsEmptyGroup={setIsEmptyGroup}
          reCallgrooup={reCallgrooup}
          setWindowExpanded={setExpanded}
          expandedWindow={expanded}
        />
      ) : (
        <OtherChatComponent
          setIsEmptyGroup={setIsEmptyGroup}
          reCallgrooup={reCallgrooup}
          setWindowExpanded={setExpanded}
          expandedWindow={expanded}
        />
      )}
    </Box>
  );
};

export default ChatLeftSection;
