import { Box, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CancelIcon from "@mui/icons-material/Cancel";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { LoadingButton } from '@mui/lab';

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "69vw",
    backgroundColor: "#fff",
    borderRadius: "10px",
    p: 1,

  };

function PCBImageEditor({isOpen, onCloseModal, image, setImage}:any) {

const imageEditor = React.createRef<HTMLDivElement | any>();
const [loader, setLoader] = useState<any>(false)

const handleUpdateImage = () => {
    setLoader(true)
    const updatedImage = imageEditor?.current?.imageEditorInst;

    const updatedData = updatedImage.toDataURL().split(",")[1];
    const byteCharacters = atob(updatedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
      type: "image/png",
    });

    

    const updatedFile = new File([file], "EditedImage.png", { type: "image/png" });

  


    setImage(updatedFile);
    onCloseModal();
    
    setLoader(false);
    // setImage(file);
    // closeModal();
  };


  // Inside your component
useEffect(() => {
    if (imageEditor?.current) {
      const editorInstance = imageEditor.current.imageEditorInst;
  
      // Override the addShape method to only allow rectangles
      const originalAddShape = editorInstance.addShape;
      editorInstance.addShape = (type:any, options:any) => {
        if (type === "rect") {
          return originalAddShape.call(editorInstance, type, options);
        }
        console.warn(`Shape type "${type}" is disabled.`);
        return null;
      };
    }
  }, [imageEditor]);


  
  return (
    <Modal
    disableEnforceFocus
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
    <div style={style}>
      
    <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit PCB Image
            </Typography> 
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>

          <ImageEditor
                includeUI={{
                    loadImage: {
                        path: image,
                        name: "EditedImage",
                    },
                    menu: [
                        "shape",
                        // "filter",
                        // "text",
                        // "mask",
                        // "icon",
                        // "draw",
                        // "crop",
                        // "flip",
                        // "rotate",
                        // "resize",
                    ],
                    uiSize: {
                        width: "100%",
                        height: "73vh",
                    },
                    menuBarPosition: "left",
                    initMenu: "shape", // Open shape menu by default
                    shape: {
                        // Enable only rectangles
                        rect: false,
                        // {
                        //   fill: "#0390fc", // Default fill color for the rectangle
                        //   stroke: "#0390fc", // Default stroke color for the rectangle
                        //   strokeWidth: 2,
                        // },
                        triangle: false, // Disable triangle option
                        circle: false,   // Disable circle option
                        ellipsis: false, // Disable ellipsis (another term for oval/circle)
                      },
                }}
                cssMaxHeight={500}
                cssMaxWidth={1000}
                selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                }}
                usageStatistics={true}
                ref={imageEditor}
                
            />

<Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}>
            <LoadingButton
              size="small"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
              variant="contained"
              onClick={handleUpdateImage}
              loading={loader}>
              Save
            </LoadingButton>
          </Box>
        
    </div>
    </Modal>
  )
}

export default PCBImageEditor






















// import { Box, Modal, Typography } from '@mui/material'
// import React, { useState } from 'react'
// import CancelIcon from "@mui/icons-material/Cancel";
// import ImageEditor from "@toast-ui/react-image-editor";
// import "tui-image-editor/dist/tui-image-editor.css";
// import "tui-color-picker/dist/tui-color-picker.css";
// import { LoadingButton } from '@mui/lab';

// const style = {
//     position: "absolute" as "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "69vw",
//     backgroundColor: "#fff",
//     borderRadius: "10px",
//     p: 1,

//   };

// function PCBImageEditor({isOpen, onCloseModal, image, setImage}:any) {
// console.log(image, "imagee editor");
// const imageEditor = React.createRef<HTMLDivElement | any>();
// const [loader, setLoader] = useState<any>(false)

// const handleUpdateImage = () => {
//     setLoader(true)
//     const updatedImage = imageEditor?.current?.imageEditorInst;

//     const updatedData = updatedImage.toDataURL().split(",")[1];
//     const byteCharacters = atob(updatedData);
//     const byteNumbers = new Array(byteCharacters.length);
//     for (let i = 0; i < byteCharacters.length; i++) {
//       byteNumbers[i] = byteCharacters.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     const file = new Blob([byteArray], {
//       type: "image/png",
//     });

//     console.log(file, "imagee file", file?.type, image?.name);

//     const updatedFile = new File([file], "EditedImage.png", { type: "image/png" });

//     console.log(updatedFile, "imagee");
    
    
//     setLoader(false)
//     // setImage(file);
//     // closeModal();
//   };


  
//   return (
//     <Modal
//     disableEnforceFocus
//     open={isOpen}
//     onClose={onCloseModal}
//     aria-labelledby="modal-modal-title"
//     aria-describedby="modal-modal-description">
//     <div style={style}>
      
//     <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
//             <Typography
//               style={{
//                 fontSize: "1rem",
//                 fontWeight: "600",
//                 width: "100%",
//                 textAlign: "center",
//                 marginRight: "-2rem",
//               }}>
//               Edit PCB Image
//             </Typography> 
//             <CancelIcon
//               titleAccess="Close"
//               sx={{ cursor: "pointer", margin: "0.5rem" }}
//               onClick={onCloseModal}
//             />
//           </Box>

//           <ImageEditor
//                 includeUI={{
//                     loadImage: {
//                         path: URL.createObjectURL(image),
//                         name: "EditedImage",
//                     },
//                     menu: [
//                         "shape",
//                         // "filter",
//                         // "text",
//                         // "mask",
//                         // "icon",
//                         // "draw",
//                         // "crop",
//                         // "flip",
//                         // "rotate",
//                         // "resize",
//                     ],
//                     uiSize: {
//                         width: "100%",
//                         height: "73vh",
//                     },
//                     menuBarPosition: "left",
//                 }}
//                 cssMaxHeight={500}
//                 cssMaxWidth={1000}
//                 selectionStyle={{
//                     cornerSize: 20,
//                     rotatingPointOffset: 70,
//                 }}
//                 usageStatistics={true}
//                 ref={imageEditor}
//             />

// <Box
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               marginTop: "1rem",
//             }}>
//             <LoadingButton
//               size="small"
//               sx={{
//                 padding: "0.5rem",
//                 lineHeight: 1,
//                 "&:hover": {
//                   transform: 'Scale(1.05)',
//                   transition: 'transform 0.5s ease',
//                 },
//                 '.MuiCircularProgress-root': {
//                   color: 'primary.main',
//                 },
//               }}
//               variant="contained"
//               onClick={handleUpdateImage}
//               loading={loader}>
//               Save
//             </LoadingButton>
//           </Box>
        
//     </div>
//     </Modal>
//   )
// }

// export default PCBImageEditor



