const pieData = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  
  const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042"];
  const barGraphData = [
    { name: "Jan", uv: 400, pv: 2400 },
    { name: "Feb", uv: 300, pv: 1398 },
    { name: "Mar", uv: 200, pv: 9800 },
    { name: "Apr", uv: 100, pv: 9300 },
  ];
  
  const paretoData = [
    { name: "A", value: 100, cumulative: 20 },
    { name: "B", value: 80, cumulative: 55 },
    { name: "C", value: 60, cumulative: 80 },
    { name: "D", value: 40, cumulative: 95 },
    { name: "E", value: 20, cumulative: 100 },
  ];
  
  const tableData = [
    { id: 1, name: "Task A", progress: "Completed" },
    { id: 2, name: "Task B", progress: "In Progress" },
    { id: 3, name: "Task C", progress: "Not Started" },
  ];

  export {pieData,COLORS,barGraphData,paretoData,tableData}