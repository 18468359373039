import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import PCBImageEditor from "./PCBImageEditor";

const BOMRoboflowDataFetchComponent: any = (props: any) => {
  const { vault } = useRouteParams<any>();
  const { getData, data ,setStep,step} = props;
  const cropModal:any = useBaseParamsModal();
    const [cropImage, setCropImage] = useState<any>(null);
    const [cropCount, setCropCount] = useState<any>(0);
  const [topImage, setTopImage] = useState<File | null>(null);
  const [topRefImage, setTopRefImage] = useState<File | null>(null);
  const [bottomImage, setBottomImage] = useState<File | null>(null);
  const [bottomRefImage, setBottomRefImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [topDimensions, setTopDimensions] = useState({ length: "", width: "" });
  const [bottomDimensions, setBottomDimensions] = useState({
    length: "",
    width: "",
  });

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = e.target.files?.[0] || null;
    setImage(file);
    validateSubmit();
  };

  const handleDimensionChange = (
    key: "length" | "width",
    value: string,
    setDimensions: React.Dispatch<
      React.SetStateAction<{ length: string; width: string }>
    >
  ) => {
    setDimensions((prev) => ({ ...prev, [key]: value }));
    validateSubmit();
  };

  const validateSubmit = () => {
    if (
      topImage &&
      topRefImage &&
      bottomImage &&
      bottomRefImage &&
      topDimensions.length &&
      topDimensions.width &&
      bottomDimensions.length &&
      bottomDimensions.width
    ) {
      setIsSubmitEnabled(true);
    } else {
      setIsSubmitEnabled(false);
    }
  };

  React.useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      setTopDimensions({
        length: data.top_ref_length,
        width: data.top_ref_width,
      });
      setBottomDimensions({
        length: data.bottom_ref_length,
        width: data.bottom_ref_width,
      });
      setTopImage(data?.top_image);
      setTopRefImage(data?.top_reference_image);
      setBottomImage(data?.bottom_image);
      setBottomRefImage(data?.bottom_reference_image);
      if (
        data?.top_image &&
        data?.top_reference_image &&
        data?.bottom_image &&
        data?.bottom_reference_image &&
        data.top_ref_length &&
        data.top_ref_width &&
        data.bottom_ref_length &&
        data.bottom_ref_width
      ) {
        setIsSubmitEnabled(true);
      } else {
        setIsSubmitEnabled(false);
      }
    }
  }, [data]);

  const handleSave = () => {
    const formData = new FormData();

    // Append binary files
    if (topImage) formData.append("top_image", topImage);
    if (topRefImage) formData.append("top_reference_image", topRefImage);
    if (bottomImage) formData.append("bottom_image", bottomImage);
    if (bottomRefImage) formData.append("bottom_reference_image", bottomRefImage);

    // Append dimension data
    formData.append("top_ref_length", topDimensions.length);
    formData.append("top_ref_width", topDimensions.width);
    formData.append("bottom_ref_length", bottomDimensions.length);
    formData.append("bottom_ref_width", bottomDimensions.width);

    setLoading(true);
    API.put(`/xcpep/pcb_info_for_vault/${vault}/`, formData, {}, 0)
      .then((res: any) => {
        setLoading(false);
        setStep((prev:any)=>prev+1)
        getData();
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const renderImageSection = (
    title: string,
    image: File | null | string,
    setImage: React.Dispatch<React.SetStateAction<File | null>>,
    refImage: File | null | string,
    setRefImage: React.Dispatch<React.SetStateAction<File | null>>,
    dimensions: { length: string; width: string },
    setDimensions: React.Dispatch<
      React.SetStateAction<{ length: string; width: string }>
    >
  ) => {
    console.log(image, "imagee");
    console.log(refImage, "ref imagee");

  
    
    return (
      <>



<Paper
        elevation={1}
        sx={{ p: 2, mb: 2, borderRadius: 2, backgroundColor: "#f9f9f9" }}
      >
        {/* <Typography variant="subtitle2" mb={1}>
          {title}
        </Typography> */}
        <Grid container spacing={2} alignItems="center">
          {/* Image Upload Section */}
          <Grid item xs={6}>
            <Box
              sx={{
                border: "1px dashed grey",
                borderRadius: 1,
                height: 170,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                position: "relative",
              }}
            >
              {image ? (
                <>
                  <img
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt="Uploaded"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                  {/* Edit Button */}
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ position: "absolute", top: 5, right: 5 }}
                    component="label"
                  >
                    <EditIcon />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, setImage)}
                    />
                  </IconButton>
                </>
              ) : (
                <Button
                  size="small"
                  component="label"
                  startIcon={<UploadFileIcon />}
                >
                  Upload {title} Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, setImage)}
                  />
                </Button>
              )}
            </Box>
          </Grid>
  
          {/* Reference Image Section */}
          <Grid item xs={6}>
            <Box
              sx={{
                border: "1px dashed grey",
                borderRadius: 1,
                height: 170,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                position: "relative",
              }}
            >
              {refImage ? (
                <>
                  <img
                    src={
                      typeof refImage === "string"
                        ? refImage
                        : URL.createObjectURL(refImage)
                    }
                    alt="Reference"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                  {/* Edit Button */}
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ position: "absolute", top: 25, right: 2 }}
                    component="label"
                  >
                    <EditIcon />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, setRefImage)}
                    />
                  </IconButton>
                  {/* <IconButton
                    size="small"
                    color="primary"
                    sx={{ position: "absolute", top: 0, right: 2 }}
                    component="label"
                    onClick={()=> {
                      cropModal.open({
                        image:refImage,
                        setImage:setRefImage
                      });
                    }}
                  >
                    <PictureInPictureAltIcon />
                    
                  </IconButton> */}

                </>
              ) : (
                <Button
                  size="small"
                  component="label"
                  startIcon={<UploadFileIcon />}
                >
                  Upload {title} Ref Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, setRefImage)}
                  />
                </Button>
              )}
            </Box>
          </Grid>
  
          {/* Dimensions */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Length"
              type="number"
              value={dimensions.length}
              onChange={(e) =>
                handleDimensionChange("length", e.target.value, setDimensions)
              }
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Width"
              type="number"
              value={dimensions.width}
              onChange={(e) =>
                handleDimensionChange("width", e.target.value, setDimensions)
              }
              margin="dense"
            />
          </Grid>
        </Grid>
      </Paper>
      
      </>
    
    )
  };
  console.log(cropModal, "imagee");
  

  return (
    <>
    {cropModal.isOpen && (
      <PCBImageEditor isOpen={cropModal.isOpen} onCloseModal={()=> cropModal.close()} image={cropModal.propsId.image} setImage={cropModal.propsId.setImage} /> 
    )}
    <Box
      sx={{
        p: 2,
        maxWidth: "60vw",
        margin: "auto",
        backgroundColor: "#ffffff",
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
      {renderImageSection(
        "Top Side",
        topImage,
        setTopImage,
        topRefImage,
        setTopRefImage,
        topDimensions,
        setTopDimensions
      )}
      {renderImageSection(
        "Bottom Side",
        bottomImage,
        setBottomImage,
        bottomRefImage,
        setBottomRefImage,
        bottomDimensions,
        setBottomDimensions
      )}
      <Box textAlign="center" mt={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleSave}
          sx={{ whiteSpace: "nowrap" }}
          disabled={!isSubmitEnabled}
        >
          Submit Request and Fetch Data
        </LoadingButton>
      </Box>
    </Box>
    </>
  );
};

export default BOMRoboflowDataFetchComponent;
