import * as React from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  ButtonProps,
  Divider,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  InputAdornment
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import Swal from "sweetalert2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ADMIN } from "../../Redux/Services/admin.service";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: '93vw', xl: "80vw" },
  maxWidth: '93vw',
  maxHeight: { lg: '90vh', xl: "90vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: { lg: 1, xl: 2 },
};

const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

interface IFormulaModalProps {
  isOpen: any;
  onCloseModal: any;
  data: any;
  getTreeData?: any;
  setCounterApiCall?: any;
  setParentCount?: any
}

function FormulaModal(props: IFormulaModalProps) {
  const { isOpen, onCloseModal, data, getTreeData, setCounterApiCall, setParentCount } = props;
  const { categoryId } = useRouteParams<any>();
  const [result, setResult] = React.useState<any>(data?.formula);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [tableData, setTableData] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [formulaCheckedStatus, setFormulaCheckedStatus] =
    React.useState<any>(false);
  const [referenceData, setReferenceData] = React.useState<any>([]);



  const open = Boolean(anchorEl);
  const poid = open ? "simple-popover" : undefined;

  const getCalcCategory = (name: string) => {
    switch (name) {
      case "Overheads":
        return 0;
      case "Raw Material":
        return 1;
      case "Bought-Out Part":
        return 2;
      case "Labour":
        return 3;
      case "Machine":
        return 4;
      case "Tool":
        return 5;
      case "Setup":
        return 6;
      default:
        return 0;
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(`val.${text}`);
    ADMIN.toast.info("Alias copied to clipboard");
  };
  const copyToClipboardFormula = (text: string) => {
    navigator.clipboard.writeText(`${text}`);
    ADMIN.toast.info("Alias copied to clipboard");
  };

  const columns: GridColDef[] = [
    { field: "cost_calculator__name", headerName: "Calculator Name", flex: 1 },
    {
      field: "alias",
      headerName: "Alias",
      flex: 0.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.value}</span>
          <Tooltip title="Copy alias">
            <IconButton
              onClick={() => copyToClipboard(params.value)}
              size="small"
              style={{ marginLeft: 8 }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getTableData = () => {
    API.get(
      `/config/get_costing_configuration_alias/`,
      {
        category_id: categoryId,
        calc_category:
          data?.calc_category,
        estimation_id: (data.type !== "process" && data.name !== "Bought-Out Part" && data.type !== "sub_calculator_cat") ? data?.id : (data.name === "Bought-Out Part" && data.type === "sub_calculator_cat") ? null : undefined,
        process_id: data.type === "process" ? data?.id : undefined,
        commodity_id: data?.commodity_id || undefined,

      },
      0
    )
      .then((res: any) => {
        setTableData(res.data?.data);
        setReferenceData(res.data?.reference || []);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getTableData();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (data) {
      setResult(data.formula);
    }
  }, [data]);

  const handleApply = () => {
    setLoader(true);
    if (data?.type !== "process" && data.name !== "Bought-Out Part" && data.type !== "sub_calculator_cat") {
      API.put(
        `config/add_configuration_estimation_group/${data.id}/`,
        {
          final_formula: result,
        },
        {},
        0
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:green;">Formula Updated Successfully</p>   
             </div>`,
          });
          setLoader(false);
          getTreeData();
          setCounterApiCall((prev: any) => prev + 1);
          onCloseModal();
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:red;">Formula Update Failed</p>
             </div>`,
          });
        });
    } else if (data.name === "Bought-Out Part" && data.type === "sub_calculator_cat") {
      API.put(
        `api/db/costing_category/${categoryId}/`,
        {
          bop_final_formula: result,
        },
        {},
        0
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:green;">Formula Updated Successfully</p>   
             </div>`,
          });
          setLoader(false);
          getTreeData();

          onCloseModal();
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:red;">Formula Update Failed</p>
             </div>`,
          });
        });
    }
    else {
      API.post(
        `/config/update_costing_config_process_alias/`,
        {
          formula: result,
          commodity: data?.commodity_id,
          costing_category: categoryId,
          process: data?.id,
          calc_category: data?.calc_category,
        },
        {},
        0
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:green;">Formula Updated Successfully</p>   
             </div>`,
          });
          setLoader(false);
          getTreeData();
          setCounterApiCall((prev: any) => prev + 1);
          setParentCount((prev: any) => prev + 1);
          onCloseModal();
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            customClass: {
              container: "swal2Container",
            },
            html: `<div>
            <br />
            <p style="color:red;">Formula Update Failed</p>
             </div>`,
          });
        });
    }
  };

  const correctFormula = (formula: string) => {
    const openingBrackets = "([{";
    const closingBrackets = ")]}";
    const stack: string[] = [];

    let correctedFormula = "";

    for (const char of formula) {
      if (openingBrackets.includes(char)) {
        stack.push(char); // Push opening brackets onto the stack
      } else if (closingBrackets.includes(char)) {
        const correspondingOpeningBracket =
          openingBrackets[closingBrackets.indexOf(char)];

        // If the stack is not empty and the top of the stack matches the corresponding opening bracket
        if (
          stack.length > 0 &&
          stack[stack.length - 1] === correspondingOpeningBracket
        ) {
          stack.pop(); // Pop the matching opening bracket from the stack
        } else {
          // Add a missing opening bracket to the corrected formula
          correctedFormula += correspondingOpeningBracket;
        }
      }

      correctedFormula += char; // Add the current character to the corrected formula
    }

    // Add any missing closing brackets for the remaining opening brackets on the stack
    while (stack.length > 0) {
      const missingClosingBracket =
        closingBrackets[openingBrackets.indexOf(stack.pop()!)]; // Use ! to assert non-null
      correctedFormula += missingClosingBracket;
    }
    if (correctedFormula) {
      setResult(correctedFormula);
      setFormulaCheckedStatus(false);
    }
  };

  // console.log(data, data.name === "Bought-Out Part" && data.type === "sub_calculator_cat", data.name === "Bought-Out Part", data.type === "sub_calculator_cat");


  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        {/* Modal Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="600">
            Add/Update Formula ({data?.name})
          </Typography>
          <IconButton title="Close" onClick={onCloseModal}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />

        {/* Modal Body */}
        <Box sx={{ display: "flex", mt: 2, alignItems: "flex-start" }}>
          {/* Final Formula Field */}
          <Box sx={{ width: "60%", pr: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <Divider sx={{ borderColor: "primary.light" }} />

                <b>Formula for {data?.name} :</b>
              </Typography>
              {formulaCheckedStatus ? (
                <FactCheckIcon
                  sx={{
                    cursor: "pointer",
                    color: "green",
                  }}
                  titleAccess="Check formula"
                  onClick={() => {
                    correctFormula(result);
                    setFormulaCheckedStatus(true);
                  }}
                />
              ) : (
                <FactCheckOutlinedIcon
                  sx={{
                    cursor: "pointer",
                    // color:  "green" : "",
                  }}
                  titleAccess="Check formula"
                  onClick={() => {
                    correctFormula(result);
                    setFormulaCheckedStatus(true);
                  }}
                />
              )}
            </Box>
            <TextField
              fullWidth
              placeholder={`formula for ${data?.name}`}
              value={result}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;
                // Allow only letters, special characters, and parentheses
                if (/^[^0-9]*$/.test(newValue)) {
                  setResult(newValue);
                }
              }}
              InputProps={{
                endAdornment: result && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setResult("")}
                      edge="end"
                      aria-label="clear"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "white",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.light",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: { lg: '58vh', xl: '64vh' },
                // alignItems: "flex-start",
                // columnGap: "0.5rem",
                // padding: "0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1rem",
                  padding: "0.5rem",
                  fontWeight: "600",
                  backgroundColor: "#ddefff",
                }}
              >
                Formula Fusion: Multi-Operation Showcase
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "0.5rem",
                  padding: "0.5rem",
                }}
              >
                <Table>
                  <TableBody>
                    {referenceData &&
                      referenceData?.length > 0 &&
                      referenceData
                        ?.slice(0, 13)
                        ?.map((item: any, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#edfbee",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#fffaea",
                                }}
                              >
                                <Tooltip title="Copy alias">
                                  <IconButton
                                    onClick={() =>
                                      copyToClipboardFormula(item?.formula)
                                    }
                                    size="small"
                                    style={{ marginLeft: 8 }}
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                {item?.formula}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#edfbee",
                                }}
                              >
                                {item?.operation} {item?.operation === "OR" ? `(first nonzero value will be shown)`:""}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
                <Divider
                  sx={{ borderColor: "primary.light" }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Table>
                  <TableBody>
                    {referenceData &&
                      referenceData?.length > 0 &&
                      referenceData?.slice(13)?.map((item: any, index: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "#edfbee",
                              }}
                            >
                              {14 + index}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "#fffaea",
                              }}
                            >
                              <Tooltip title="Copy alias">
                                <IconButton
                                  onClick={() =>
                                    copyToClipboardFormula(item?.formula)
                                  }
                                  size="small"
                                  style={{ marginLeft: 8 }}
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {item?.formula}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "#edfbee",
                              }}
                            >
                              {item?.operation}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>

          {/* Table */}
          <Box sx={{ width: "40%", height: { lg: '37rem', xl: "46rem" }, mt: 0 }}>
            <DataGrid
              headerHeight={38}
              rowHeight={38}
              rows={tableData}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          </Box>
        </Box>

        {/* Modal Footer */}
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <LoadingButton
            onClick={handleApply}
            loading={loader}
            variant="contained"
          >
            Apply
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default FormulaModal;
