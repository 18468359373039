import {
  alpha,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Skeleton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddCostingCategoryModal from "./AddCostingCategoryModal";
import CostingTreeTemplateFetchedData from "./CostingTreeTemplateFetchedData";
import CreateCostCalculatorView from "./CreateCostCalculatorView";
import calculator from "../../Assets/images/calculator.svg";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import EditCategoryModal from "./EditCategoryModal";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Swal from "sweetalert2";
import CostCategoryInfoModal from "./CostCategoryInfoModal";
import BucketIcon from "../../Assets/images/svgs/bucket.svg";
import BucketViewModal from "./BucketViewModal";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import { AUTH } from "../../Redux/Services/auth.service";
import { useEffect, useRef, useState } from "react";
import CostingConfigSyncModal from "./CostingConfigSyncModal";
import PerformCostingModal from "./PerformCostingModal";
import SyncIcon from "@mui/icons-material/Sync";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import CostingCategoryTopHeaderMenu from "./CostingCategoryTopHeaderMenu";
import CheckBoxValidation from "./CheckBoxValidation";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useCollection } from "../../CustomHook/useCollection";
import { CalculatorInput } from "../../Redux/Reducers/calculatorInput.reducer";
import ShareIcon from '@mui/icons-material/Share';
import { ShareInstanceModal } from "../../ComponentUI/CostingScenarios/ShareInstanceModal";

export interface ICostingTemplateCategoryProps {
  expanded: string | false;
  permission: any;
  setCattegory?: any;
  handleShowChange: (panel: string) => void;
  projectname?: any;
}

export const UserContext = React.createContext<any>(null);

export const commodity_color = "#FFF5CC"; // Very Light Gold
export const process_color = "#D4F1F4"; // Light Cyan Blue

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    //@ts-ignore
    // ...theme.applyStyles("dark", {
    //   color: theme.palette.grey[300],
    // }),
  },
}));

export function CostingTemplateCategory(props: ICostingTemplateCategoryProps) {
  const { expanded, handleShowChange, permission, setCattegory } = props;
  const addCostingCategory = useBaseModal();
  const shareModal = useBaseParamsModal();
  const editCostingCategory = useBaseParamsModal();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryGroup,
    calcId,
    calcSequence,
    config_id,
    costingType,
    costingSubType,
    configMirror
  } = useRouteParams<any>();
  // console.log("datatype", datatype);
  const { width } = useWindowDimensions();
  const inputs: any = useCollection<CalculatorInput>();
  const itemRefs = useRef<any>({});
  const userProfile = useSelector(userSelectors.selectAll);
  const history = useHistory();
  const bucketOpen = useBaseModal();
  const openSyncModel = useBaseParamsModal();
  const openPerformCostingModal = useBaseParamsModal();
  const [loading, setLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [premissionData, setPremissionData] = React.useState<any>(null);
  const [ProductLoader, setProductLoader] = React.useState<any>(false);
  const [pcType, setPcType] = React.useState<any>(null);
  const [SearchProductLoader, setSearchProductLoader] =
    React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [RMLoading, setRMLoading] = React.useState<any>(false);
  const [PSLoading, setPSLoading] = React.useState<any>(false);
  const [OHLoading, setOHLoading] = React.useState<any>(false);
  const [ChildLoading, setChildLoading] = React.useState<any>(false);
  const [collapseLoading, setCollapseLoading] = React.useState<any>(false);
  const [RMCollapseLoading, setRMCollapseLoading] = React.useState<any>(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [category, setCategory] = React.useState<any>({});
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [RawMaterialHierarchy, setRawMaterialHierarchy] = React.useState<any>(
    []
  );
  const [ProcessHierarchy, setProcessHierarchy] = React.useState<any>([]);
  const [OverheadHierarchy, setOverheadHierarchy] = React.useState<any>([]);
  const [syncCount, setSyncCount] = React.useState<any>(0);
  const [isCostSync, setIsCostSync] = useState(false);
  const [pfLoading, setPfLoading] = useState(false);
  const [CategoryDCList, setCategoryDCList] = React.useState<any>([]);
  const [CategoryBOPList, setCategoryBOPList] = React.useState<any>([]);
  const [CategorySearchListDC, setCategorySearchDCList] = React.useState<any>(
    []
  );
  const [withLinkStatus, setWithLinkStatus] = React.useState<any>(false);
  const [CategorySearchListBOP, setCategorySearchBOPList] = React.useState<any>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [ParentCount, setParentCount] = React.useState<any>(0);
  const [dataType, setDataType] = React.useState<any>("");
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>(null);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(1);
  const [bucketRefreshCounter, setBucketRefreshCounter] =
    React.useState<any>(0);
  const [wolcounter, setWolCounter] = React.useState<any>(0);
  const [hideCounter, setHideCounter] = useState(0);
  const [hideCommodity, setHideCommodity] = useState(false); // Default to true initially
  const [hideProcess, setHideProcess] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState<any>("");
  const [downloadLoading, setDownloadLoading] = React.useState<any>(false);
  const [selectedForDownload, setSelectedForDownload] = React.useState<any>();
  const [bucketList, setBucketList] = React.useState<any>([]);
  const [bucketCount, setBucketCount] = React.useState<any>(0);
  const [hierarchyCount, setHierarchyCount] = React.useState<any>(0);
  const [categoryInfo, setCategoryInfo] = React.useState<any>({});
  const [categoryInfoLoader, setCategoryInfoLoader] =
    React.useState<any>(false);
    const [selectMenuOption, setSelectMenuOption] = React.useState<any>("Linked");
    const [selectCategoryType,setSelectCategoryType] = React.useState<any>(1);
  const [anchorElM, setAnchorElM] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorElM);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElM(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElM(null);
  };

  React.useEffect(()=>{
    if(costingType == 2){
    if(selectMenuOption === "Linked"){
      history.push(
        `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/${configMirror}/${calcId}/${calcSequence}/${config_id}/${2}/${1}`
      );
    }else{
      history.push(
        `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/${configMirror}/${calcId}/${calcSequence}/${config_id}/${2}/${2}`
      );
    }
  }
  },[selectMenuOption])
  const infoModal:any = useBaseParamsModal();
  const [treeexpanded, setTreeExpanded] = React.useState<string | false>(
    "panel1"
  );
  const [addFectchCounter, setAddFectchCounter] = React.useState<any>(0);
  useEffect(() => {
    const storedHideCommodity =
      sessionStorage.getItem("HideCommodity") === "true";
    const storedHideProcess = sessionStorage.getItem("HideProcess") === "true";

    setHideCommodity(storedHideCommodity);
    setHideProcess(storedHideProcess);
    

    const data = sessionStorage.getItem("Costingconfigurationnode");

    if (
      data &&
      data === JSON.stringify(["Parent", "1Material", "4Process", "9Overheads"])
    ) {
      setIsExpanded(false);
    }
  }, []);

  const getBucketList = () => {
    API.get(
      `/config/configuration_drop_bucket/`,
      {
        category_id: categoryId,
      },
      0
    )
      .then((res: any) => {
        setBucketList(res.data);
        setBucketCount(res.data?.length || 0);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getBucketList();
  }, [categoryId]);

  const getTabsInfo=()=>{
    API.get("/cost/scenario_costing_configuration/",{
      check_scenario:true,
      project:projectId
    },0).then((res:any)=>{
    setWithLinkStatus(res.data.scenario_category_exists);
    }).catch((err:any)=>{
      console.log("Server error");
    })
  }


  React.useEffect(()=>{
    if(projectId != 0){
      getTabsInfo();
    }
  },[projectId])
  const [categoryCount, setCategoryCount] = React.useState<any>(0);
  const [countLoader, setCountLoader] = React.useState<any>(false);
  const [expandedCategory, setCategoryExpanded] = React.useState<any>("DC");

  React.useEffect(() => {
    if (categoryGroup != 0) {
      setCategoryExpanded(categoryGroup === "dc" ? "DC" : "BOP");
    }
  }, [categoryGroup]);

  const darkenColor = (color: string, percent: number) => {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    // Reduce the RGB values by the given percent
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert the RGB values back to a hexadecimal string and ensure they are 2 characters long
    const toHex = (value: number) => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex; // Add leading 0 if needed
    };

    // Return the darkened color in hex format
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const Addmodalhandler = () => {
    if(costingType ==1){
    addCostingCategory.open();
    }
  };

  useEffect(() => {
    const storedItem = sessionStorage.getItem("SelectedTreeItem");
    if (storedItem) {
      try {
        setSelectedTreeData(JSON.parse(storedItem)); // Parse JSON back to an object
      } catch (error) {
        console.error(
          "Failed to parse SelectedTreeItem from sessionStorage:",
          error
        );
        sessionStorage.removeItem("SelectedTreeItem"); // Clear invalid data
      }
    }
  }, [sessionStorage.getItem("SelectedTreeItem")]);

  // Category Count
  React.useEffect(() => {
    setCountLoader(true);
    API.get(
      `/config/get_costing_configuration_based_on_costing_category/`,
      {
        all_costing_category_count: true,
        project: projectId,
        costing_type: costingType,
      },
      0
    )
      .then((res: any) => {
        setCategoryCount(res?.data);
        setCountLoader(false);
      })
      .catch((err: any) => {
        setCountLoader(false);
        console.log("Server error");
      });
    // console.log("projectId, productId, categoryId dependency");
  }, [projectId, productId, categoryId,costingType,costingSubType]);

  const getTreeDataWOL = (type: any) => {
    // setCollapseLoading(true);

    setDataType("");
    if (categoryGroup === "dc") {
      if (type === "category") {
        
        setIsLoading(true);
        setAllHierarchy([])
      } else if (type === "raw_material") {
        setRMLoading(true);
        setRawMaterialHierarchy([]);
      } else if (type === "process") {
        setPSLoading(true);
        setProcessHierarchy([]);
      } else if (type === "overhead") {
        setOHLoading(true);
        setOverheadHierarchy([]);
      }
    } else if (categoryGroup === "bop") {
      if (type === "category") {
        setIsLoading(true);
      } else if (type === "raw_material") {
        setRMLoading(true);
      } else if (type === "overhead") {
        setOHLoading(true);
      }
    }
    const hideComm = sessionStorage.getItem("HideCommodity");
    const hidePro = sessionStorage.getItem("HideProcess");

    API.get(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        project: projectId,
        costing_config_hierarchy: categoryId,
        split_filter: type,
        hide_commodity: hideComm,
        hide_process: hidePro,
      },
      0
    )
      .then((res: any) => {
        if (categoryGroup === "dc") {
          if (type === "category") {
          
            setAllHierarchy(res.data[0]?.data);
            setIsLoading(false);
          } else if (type === "raw_material") {
     
            setRawMaterialHierarchy(res.data);
            setRMLoading(false);
          } else if (type === "process") {
           
            setProcessHierarchy(res.data);
            setPSLoading(false);
          } else if (type === "overhead") {
          
            setOverheadHierarchy(res.data);
            setOHLoading(false);
          }
        } else if (categoryGroup === "bop") {
          if (type === "category") {
            setAllHierarchy([]);
            setAllHierarchy(res.data[0]?.data);
            setIsLoading(false);
          } else if (type === "raw_material") {
            setRawMaterialHierarchy([]);
            setRawMaterialHierarchy(res.data);
            setRMLoading(false);
          } else if (type === "overhead") {
            setOverheadHierarchy([])
            setOverheadHierarchy(res.data);
            setOHLoading(false);
          }
        }
        setRMLoading(false);
        setPSLoading(false);
        setOHLoading(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setRMLoading(false);
        setPSLoading(false);
        setOHLoading(false);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    setCategoryDCList([]);
    if (expandedCategory === "DC" && searchTerm === "") {
      setProductLoader(true);
      API.get(
        `/config/get_costing_configuration_based_on_costing_category/`,
        {
          all_costing_category: true,
          project: projectId,
          category_type: "DC",
          costing_type: costingType,
        },
        0
      )
        .then((res: any) => {
          setCategoryDCList(res?.data);
          setProductLoader(false);
        })
        .catch((err: any) => {
          setProductLoader(false);
          console.log("Server error");
        });
    } else if (expandedCategory === "BOP" && searchTerm === "") {
      setProductLoader(true);
      setCategoryBOPList([]);
      API.get(
        `/config/get_costing_configuration_based_on_costing_category/`,
        {
          all_costing_category: true,
          project: projectId,
          category_type: "BOP",
          costing_type: costingType,
        },
        0
      )
        .then((res: any) => {
          setCategoryBOPList(res?.data);
          setProductLoader(false);
        })
        .catch((err: any) => {
          setProductLoader(false);
          console.log("Server error");
        });
    }
  }, [productId, expandedCategory, ParentCount, syncCount, counterApiCall,costingType,costingSubType]);

  const getCategoryInfo = () => {
    setCategoryInfoLoader(true);
      API.get(
        `/config/get_costing_configuration_based_on_costing_category/`,
        {
          // project: projectId,
          costing_category_id: categoryId,
        },
        0
      )
        .then((res: any) => {
          setCategoryInfo(res?.data);
          setCategoryInfoLoader(false);
        })
        .catch((err: any) => {
          setCategoryInfoLoader(false);
          console.log("Server error");
        });
  }
  React.useEffect(() => {
    if (categoryId) {
      getCategoryInfo();
    }
  }, [
    productId,
    expandedCategory,
    ParentCount,
    syncCount,
    counterApiCall,
    categoryId,
  ]);


  const getSearchedCategorgy = (searchTerm: any) => {
    if (searchTerm !== "" && searchTerm.length > 0) {
      setSearchProductLoader(true);
      setCategorySearchDCList([]);
      setCategorySearchBOPList([]);
      API.get(
        `/config/get_costing_configuration_based_on_costing_category/`,
        {
          all_costing_category: true,
          project: projectId,
          search: searchTerm,
          costing_type: costingType,
        },
        0
      )
        .then((res: any) => {
          setCategorySearchDCList(res?.data?.DC);
          setCategorySearchBOPList(res?.data?.BOP);
          setSearchProductLoader(false);
        })
        .catch((err: any) => {
          setSearchProductLoader(false);
          console.log("Server error");
        });
    }
  };

  // Search Count
  const getSearchedCategorgyCount = (searchTerm: any) => {
    setCountLoader(true);
    API.get(
      `/config/get_costing_configuration_based_on_costing_category/`,
      {
        all_costing_category_count: true,
        project: projectId,
        search: searchTerm,
        costing_type: costingType,
      },
      0
    )
      .then((res: any) => {
        setCategoryCount(res?.data);
        setCountLoader(false);
      })
      .catch((err: any) => {
        setCountLoader(false);
        console.log("Server error");
      });
  };

  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await getSearchedCategorgyCount(value);
      await getSearchedCategorgy(value);
    }, 1000)
  ).current;

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    ExactDebounce(value); // Call the debounced function
  };

  const clearFetchedCostModels = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to clear all fetched cost models?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(
          `config/get_costing_configuration_based_on_costing_category/${categoryId}/`,
          { clear_all: true },
          0
        )
          .then(() => {
            setParentCount((prev: any) => prev + 1);
            setWolCounter((prev: any) => prev + 1);
          })
          .catch((e) => {
            Swal.fire({
              title: "Error!",
              text: e?.response?.data?.[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleMenuItemClick = (option: any) => {
    if (option === "Category") {
      setPcType("Category");
      handlePerformCosting();
    } else if (option === "Project") {
      setPcType("Project");
      handlePerformCosting();
    }
    handleCloseMenu(); // Close the menu after selection
  };

  const predefinedNodeItems = [
    "Parent",
    "1Material",
    "2RawMaterial",
    "3BoughtOutPart",
  ];

  const getTreeDataHideRow = (type: any) => {
    const hideComm = sessionStorage.getItem("HideCommodity");
    const hidePro = sessionStorage.getItem("HideProcess");
    if (categoryGroup === "dc") {
      if (type === "category") {
        setIsLoading(true);
      } else if (type === "raw_material") {
        setRMLoading(true);
      } else if (type === "process") {
        setPSLoading(true);
      } else if (type === "overhead") {
        setOHLoading(true);
      }
    } else if (categoryGroup === "bop") {
      if (type === "category") {
        setIsLoading(true);
      } else if (type === "raw_material") {
        setRMLoading(true);
      } else if (type === "overhead") {
        setOHLoading(true);
      }
    }
    API.get(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        project: projectId,
        costing_config_hierarchy: categoryId,
        split_filter: type,
        hide_commodity: hideComm,
        hide_process: hidePro,
      },
      0
    )
      .then((res: any) => {
        let a: any = sessionStorage.getItem("Costingconfigurationnode");
        let val: any = JSON.parse(a) || [];
        // if (val?.length > 0) {
        // } else {
        //   sessionStorage.removeItem("Costingconfigurationnode");
        //   sessionStorage.setItem(
        //     "Costingconfigurationnode",
        //     JSON.stringify([
        //       "Parent",
        //       "1Material",
        //       "2RawMaterial",
        //       "3BoughtOutPart",
        //       "4Process",
        //       "5Labour",
        //       "6Machine",
        //       "7Tool",
        //       "8Setup",
        //       "9Overheads",
        //     ])
        //   );
        // }
        predefinedNodeItems?.forEach((item) => {
          // Only push the item if it's not already present in the array
          if (!val?.includes(item)) {
            val?.push(item); // Add item if not found
          }
        });
        sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(val));
        if (categoryGroup === "dc") {
          if (type === "category") {
            setAllHierarchy(res.data[0]?.data);
            setIsLoading(false);
          } else if (type === "raw_material") {
            setRawMaterialHierarchy(res.data);
            setRMLoading(false);
          } else if (type === "process") {
            setProcessHierarchy(res.data);
            setPSLoading(false);
          } else if (type === "overhead") {
            setOverheadHierarchy(res.data);
            setOHLoading(false);
          }
        } else if (categoryGroup === "bop") {
          if (type === "category") {
            setAllHierarchy(res.data[0]?.data);
            setIsLoading(false);
          } else if (type === "raw_material") {
            setRawMaterialHierarchy(res.data);
            setRMLoading(false);
          } else if (type === "overhead") {
            setOverheadHierarchy(res.data);
            setOHLoading(false);
          }
        }
        setRMLoading(false);
        setPSLoading(false);
        setOHLoading(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setRMLoading(false);
        setPSLoading(false);
        setOHLoading(false);
        setIsLoading(false);
      })
      .finally(() => {
        setRMLoading(false);
        setPSLoading(false);
        setOHLoading(false);
        setIsLoading(false);
      });
  };

  const handleToggleCommodity = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    sessionStorage.setItem("HideCommodity", `${newValue}`);
     setHideCommodity(newValue);
     setHideCounter(hideCounter+1)
    setTimeout(() => {
      getTreeDataHideRow("category");
    }, 0);
  };

  const handleToggleProcess = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    sessionStorage.setItem("HideProcess", `${newValue}`);
    setHideProcess(newValue);
    setHideCounter(hideCounter+1);
    setTimeout(() => {
      getTreeDataHideRow("category");
    }, 0);
  };

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    if (HierarchyAllData?.type === "calculator") {
      // setDataType(HierarchyAllData?.type);
      history.push(
        `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/${HierarchyAllData?.config_mirror}/${HierarchyAllData?.id}/${HierarchyAllData?.sequence_id}/${HierarchyAllData?.config_id}/${costingType}/${costingSubType}`
      );
    }
  };

  const getTreeData = (type: any) => {
    // Start loading and increment the pending request counter
    setLoading(true);
    setPendingRequests((prev) => prev + 1);

    setDataType("");
    if (categoryGroup === "dc") {
      if (type === "category") {
        setIsLoading(true);
        setAllHierarchy([])
      } else if (type === "raw_material") {
        setRMLoading(true);
        setRawMaterialHierarchy([])
      } else if (type === "process") {
        setPSLoading(true);
        setProcessHierarchy([])
      } else if (type === "overhead") {
        setOHLoading(true);
        setOverheadHierarchy([])
      }
    } else if (categoryGroup === "bop") {
      if (type === "category") {
        setIsLoading(true);
      } else if (type === "raw_material") {
        setRMLoading(true);
      } else if (type === "overhead") {
        setOHLoading(true);
      }
    }
    let a: any = sessionStorage.getItem("Costingconfigurationnode");
    let val: any = JSON.parse(a) || [];
    // if (val?.length > 0) {
    // } else {
    //   sessionStorage.setItem(
    //     "Costingconfigurationnode",
    //     JSON.stringify([
    //       "Parent",
    //       "1Material",
    //       "2RawMaterial",
    //       "3BoughtOutPart",
    //       "4Process",
    //       "5Labour",
    //       "6Machine",
    //       "7Tool",
    //       "8Setup",
    //       "9Overheads",
    //     ])
    //   );
    // }
    predefinedNodeItems?.forEach((item) => {
      // Only push the item if it's not already present in the array
      if (!val?.includes(item)) {
        val?.push(item); // Add item if not found
      }
    });
    sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(val));
    if (categoryId) {
      const hideComm = sessionStorage.getItem("HideCommodity");
      const hidePro = sessionStorage.getItem("HideProcess");
      API.get(
        "/config/get_costing_configuration_based_on_costing_category/",
        {
          project: projectId,
          costing_config_hierarchy: categoryId,
          split_filter: type,
          hide_commodity: hideComm,
          hide_process: hidePro,
        },
        0
      )
        .then((res: any) => {
          if (categoryGroup === "dc") {
            if (type === "category") {
            
              setAllHierarchy(res.data[0]?.data);
              setIsLoading(false);
            } else if (type === "raw_material") {
            
              setRawMaterialHierarchy(res.data);
              setRMLoading(false);
            } else if (type === "process") {
             
              setProcessHierarchy(res.data);
              setPSLoading(false);
            } else if (type === "overhead") {
             
              setOverheadHierarchy(res.data);
              setOHLoading(false);
            }
          } else if (categoryGroup === "bop") {
            if (type === "category") {
              setAllHierarchy([])
              setAllHierarchy(res.data[0]?.data);
              setIsLoading(false);
            } else if (type === "raw_material") {
              setRawMaterialHierarchy([])
              setRawMaterialHierarchy(res.data);
              setRMLoading(false);
            } else if (type === "overhead") {
              setOverheadHierarchy([])
              setOverheadHierarchy(res.data);
              setOHLoading(false);
            }
          }
        })
        .catch((err: any) => {
          setRMLoading(false);
          setPSLoading(false);
          setOHLoading(false);
          setIsLoading(false);
        })
        .finally(() => {
          setRMLoading(false);
          setPSLoading(false);
          setOHLoading(false);
          setIsLoading(false);
          setPendingRequests((prev) => {
            const newCount = prev - 1;
            if (newCount === 0) {
              setLoading(false); // Hide loader when all requests are done
            } else {
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
            return newCount;
          });
        });
    } else {
      setRMLoading(false);
      setPSLoading(false);
      setOHLoading(false);
      setIsLoading(false);
    }
  };
  const getTreeDataExpandCollapse = (type: any) => {
    // Start loading and increment the pending request counter
    // setLoading(true);
    setPendingRequests((prev) => prev + 1);

    setDataType("");

    let a: any = sessionStorage.getItem("Costingconfigurationnode");
    let val: any = JSON.parse(a) || [];
    // if (val?.length > 0) {
    // } else {
    //   sessionStorage.setItem(
    //     "Costingconfigurationnode",
    //     JSON.stringify([
    //       "Parent",
    //       "1Material",
    //       "2RawMaterial",
    //       "3BoughtOutPart",
    //       "4Process",
    //       "5Labour",
    //       "6Machine",
    //       "7Tool",
    //       "8Setup",
    //       "9Overheads",
    //     ])
    //   );
    // }
    predefinedNodeItems?.forEach((item) => {
      // Only push the item if it's not already present in the array
      if (!val?.includes(item)) {
        val?.push(item); // Add item if not found
      }
    });
    sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(val));
    if (categoryId) {
      const hideComm = sessionStorage.getItem("HideCommodity");
      const hidePro = sessionStorage.getItem("HideProcess");
      API.get(
        "/config/get_costing_configuration_based_on_costing_category/",
        {
          project: projectId,
          costing_config_hierarchy: categoryId,
          split_filter: type,
          hide_commodity: hideComm,
          hide_process: hidePro,
        },
        0
      )
        .then((res: any) => {
          if (categoryGroup === "dc") {
            if (type === "category") {
              setAllHierarchy(res.data[0]?.data);
              setIsLoading(false);
            } else if (type === "raw_material") {
              setRawMaterialHierarchy(res.data);
              setRMLoading(false);
            } else if (type === "process") {
              setProcessHierarchy(res.data);
              setPSLoading(false);
            } else if (type === "overhead") {
              setOverheadHierarchy(res.data);
              setOHLoading(false);
            }
          } else if (categoryGroup === "bop") {
            if (type === "category") {
              setAllHierarchy(res.data[0]?.data);
              setIsLoading(false);
            } else if (type === "raw_material") {
              setRawMaterialHierarchy(res.data);
              setRMLoading(false);
            } else if (type === "overhead") {
              setOverheadHierarchy(res.data);
              setOHLoading(false);
            }
          }
        })
        .catch((err: any) => {
          setRMLoading(false);
          setPSLoading(false);
          setOHLoading(false);
          setIsLoading(false);
        })
        .finally(() => {
          setRMLoading(false);
          setPSLoading(false);
          setOHLoading(false);
          setIsLoading(false);
          setPendingRequests((prev) => {
            const newCount = prev - 1;
            if (newCount === 0) {
              setLoading(false); // Hide loader when all requests are done
            } else {
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
            return newCount;
          });
        });
    } else {
      setRMLoading(false);
      setPSLoading(false);
      setOHLoading(false);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (categoryId === 0) {
      if (
        expandedCategory === "DC" &&
        Array.isArray(CategoryDCList) &&
        CategoryDCList?.length > 0
      ) {
        history.push(
          `/costingconfiguration-template/${projectId}/${projectname}/${CategoryDCList?.[0]?.id}/dc/false/0/0/0/${costingType}/${costingSubType}`
        );
      } else if (
        expandedCategory === "BOP" &&
        Array.isArray(CategoryBOPList) &&
        CategoryBOPList?.length > 0
      ) {
        history.push(
          `/costingconfiguration-template/${projectId}/${projectname}/${CategoryBOPList?.[0]?.id}/bop/false/0/0/0/${costingType}/${costingSubType}`
        );
      }
    } else {
      // costingconfiguration-template/:projectId/:projectname/:categoryId/:categoryGroup/:configMirror/:calcId/:calcSequence/:config_id
      history.push(
        `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/false/${calcId}/${calcSequence}/${config_id}/${costingType}/${costingSubType}`
      );
    }
  }, [
    expandedCategory,
    CategoryDCList,
    CategoryBOPList,
    CategorySearchListDC,
    CategorySearchListBOP,
  ]);

  useEffect(()=> {
   if (counterApiCall > 1) {
   
    
    getTreeData("category");
    getTreeData("raw_material");
    getTreeData("process");
    getTreeData("overhead");
   }
   if (hideCounter > 0) {
    
    
    getTreeData("category");
    getTreeData("raw_material");
    getTreeData("process");
    getTreeData("overhead");
   }
   if (addFectchCounter > 0) {
    
    
    getTreeData("category");
    getTreeData("raw_material");
    getTreeData("process");
    getTreeData("overhead");
   }
   if (wolcounter > 0) {
   
    
    getTreeData("category");
    getTreeData("raw_material");
    getTreeData("process");
    getTreeData("overhead");
   }
   
  },[
     counterApiCall,hideCommodity, hideProcess, addFectchCounter, wolcounter
  ])

  useEffect(()=> {
    
    getTreeData("category");
    getTreeData("raw_material");
    getTreeData("process");
    getTreeData("overhead");
 
   
  },[
      categoryId  
  ])

  // React.useEffect(() => {
  //   getTreeData("category");
  //   getTreeData("raw_material");
  //   getTreeData("process");
  //   getTreeData("overhead");
  // }, [counterApiCall, categoryId, ]);

  // React.useEffect(() => {
  //   getTreeData("category");
  //   getTreeData("raw_material");
  //   getTreeData("process");
  //   getTreeData("overhead");
  // }, [hideCommodity, hideProcess, addFectchCounter]);

  // React.useEffect(() => {
  //   getTreeDataExpandCollapse("category");
  //   getTreeDataExpandCollapse("raw_material");
  //   getTreeDataExpandCollapse("process");
  //   getTreeDataExpandCollapse("overhead");
  // }, [wolcounter]);

  const getCalcData = (data: any) => {
    setDataType(data?.type);

    // Only store data if it's an object or JSON-compatible
    if (data && typeof data === "object") {
      sessionStorage.setItem("SelectedTreeItem", JSON.stringify(data)); // Store as JSON string
      setSelectedTreeData(data); // Update state with new selection
    } else {
      console.error("Data is not JSON-compatible:", data);
    }
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "BOM Configuration",
      },
      0
    )
      .then((res: any) => {
        setPremissionData(res.data.action);
      })
      .catch((err: any) => {});
  }, [projectId]);

  // const handleCategoryChange = (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setCategoryExpanded(newExpanded ? 'DC' : 'BOP');
  // };

  const handleCategoryChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setCategoryExpanded(newExpanded ? panel : false);
    };

  const CategoryHandler = (item: any) => {
    sessionStorage.removeItem("SelectedTreeItem");
    
    history.push(
      `/costingconfiguration-template/${projectId}/${projectname}/${item?.id}/${
        item?.costing_type === "DC" ? "dc" : "bop"
      }/false/0/0/0/${costingType}/${costingSubType}`
    );
  };

  const outputClicked=()=>{
    API.get(
      "/config/get_costing_config_test_result/",
      { config_id: categoryId },
      0
    )
      .then((res: any) => {
        getTreeDataWOL("category");
        getTreeDataWOL("raw_material");
        getTreeDataWOL("process");
        getTreeDataWOL("overhead");
      })
      .catch((err: any) => {});
  }

  // const activeItemRef = React.useRef<HTMLDivElement | null>(null); // Reference for the active item

  // React.useEffect(() => {
  //   if (activeItemRef.current) {
  //     activeItemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }, []);

  const handlesync = () => {
    openSyncModel.open();
  };

  React.useEffect(() => {
    if (
      expandedCategory === "DC" &&
      Array.isArray(CategoryDCList) &&
      CategoryDCList?.length > 0
    ) {
      const targetCategory = CategoryDCList?.find((item) => {
        return item?.id === categoryId;
      });
      setCategory(targetCategory);
      setCattegory(targetCategory);
    }
    if (
      expandedCategory === "BOP" &&
      Array.isArray(CategoryBOPList) &&
      CategoryBOPList?.length > 0
    ) {
      const targetCategory = CategoryBOPList?.find((item) => {
        // console.log(item, categoryId);
        return item?.id === categoryId;
      });
      setCategory(targetCategory);
      setCattegory(targetCategory);
    }
  }, [CategoryDCList, CategoryBOPList, categoryId]);

  const handlePerformCosting = () => {
    openPerformCostingModal.open();
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const [infoItem, setInfoItem] = React.useState<any>({});
  const InfoModelHandler = (item: any, link?:any, costingType?:any) => {
    infoModal.open({
      link:link,
      costingType:costingType
    });
    setInfoItem(item);
  };

  // const scrollToItem = (id: any) => {
  //   const itemElement = itemRefs.current[id];
  //   if (itemElement) {
  //     console.log(`Scrolling to item with ID: ${id}`);
  //     itemElement.scrollIntoView({ behavior: "smooth", block: "center" });
  //   } else {
  //     console.log(`Item with ID: ${id} not found in itemRefs`);
  //   }
  // };

  // useLayoutEffect(() => {
  //   if (!ProductLoader && categoryId) {
  //     // Try scrolling immediately after loading completes
  //     scrollToItem(categoryId);

  //     // Add a fallback with a delay in case the items haven’t finished rendering
  //     const timeoutId = setTimeout(() => {
  //       scrollToItem(categoryId);
  //     }, 100);

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [ProductLoader, categoryId]);

  // useEffect(() => {
  //   if (categoryId && itemRefs.current[categoryId]) {
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         if (!entry.isIntersecting) {
  //           scrollToItem(categoryId); // Scroll if item is out of view
  //         }
  //       },
  //       { root: null, threshold: 0.1 }
  //     );
  //     observer.observe(itemRefs.current[categoryId]);

  //     return () => observer.disconnect(); // Clean up observer
  //   }
  // }, [categoryId]);


  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        // paddingRight: "1rem",
      }}
    >
      {addCostingCategory.isOpen && (
        <AddCostingCategoryModal
          isOpen={addCostingCategory.isOpen}
          onCloseModal={addCostingCategory.close}
          counterApiCall={setParentCount}
          setDataType={setDataType}
          setCategoryExpanded={setCategoryExpanded}
        />
      )}
      {editCostingCategory.isOpen && (
        <EditCategoryModal
          isOpen={editCostingCategory.isOpen}
          onCloseModal={editCostingCategory.close}
          counterApiCall={setParentCount}
          data={editCostingCategory.propsId}
          getTreeData={getTreeDataWOL}
        />
      )}

      {infoModal.isOpen && (
        <CostCategoryInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          item={infoItem}
          linktype={infoModal.propsId?.link}
          costingType={infoModal.propsId?.costingType}
        />
      )}
      <Box
        sx={{
          width: expanded === "leftpanel" ? { lg: "18vw", xl: "16vw" } : 0,
          minWidth: expanded === "leftpanel" ? { lg: "18vw", xl: "16vw" } : 0,
          overflow: "hidden", // Prevent content overflow
          transition:
            "all 1s ease-in, opacity 1s ease-in, visibility 0s linear 1s",
          padding: expanded === "leftpanel" ? "0 0.3rem" : 0, // Add padding only when expanded
          marginRight: "0.5rem",
          height: "89vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Center align items vertically
            gap: 1, // Reduced gap to make elements closer
            width: "100%", // Full width for proper layout
            padding: "2px 8px", // Padding for a balanced look
            // backgroundColor: "#f5f5f5", // Light background color
            borderRadius: "8px", // Rounded corners for a modern look
            transitionProperty: "width",
            transitionDuration: "1s",
            margin: "0 0.3rem 0.3rem",
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search Category..."
            autoFocus={true}
            value={searchTerm}
            onChange={handleChange}
            fullWidth // Ensures the TextField uses all available space
            sx={{
              "& .MuiOutlinedInput-root": {
                fontSize: "1rem !important",
                paddingRight: "4px 8px", // Space between input and the end
                "& fieldset": {
                  border: "1px solid #007fff", // Subtle border
                },
                "&:hover fieldset": {
                  borderColor: "#007fff", // Hover color to match theme
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#007fff", // Focus color to match theme
                },
                "& input": {
                  padding: "6px 10px", // Adjust input padding for balanced look
                },
              },
            }}
          />
          {(permission && permission?.includes("C")) && (
            <Button
              disabled={!permission?.includes("C")}
              onClick={Addmodalhandler}
              sx={{
                minWidth: "32px", // Reduced width
                height: "32px", // Reduced height
                padding: "6px", // Adjust padding for smaller size
                backgroundColor: permission?.includes("C")
                  ? "#007fff"
                  : "#ded7d7", // Button color
                color: "#fff", // Text color
                opacity: costingType ==1 ? 1 : 0.5,
                "&:hover": {
                  backgroundColor: permission?.includes("C")
                    ? "#007fff"
                    : "#ded7d7", // Darker color on hover
                },
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          )}
        </Box>
        <Box
          sx={{
            height: { lg: "81vh", xl: "85vh" },
            width: "100%",
            padding: "0.5rem",
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          {withLinkStatus&&<Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid",
            }}
          >
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                padding: "0.2rem 0.5rem",
                backgroundColor: costingType==1 ?"primary.main":"primary.light",
                color: costingType==1?"white":"primary.main",
                cursor:'pointer'
              }}
              onClick={(ed:any)=>{
                ed.preventDefault();
                ed.stopPropagation();
                history.push(
                  `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/${configMirror}/${calcId}/${calcSequence}/${config_id}/${1}/${costingSubType}`
                );
                setSelectCategoryType(1)}}
            >
              Main
            </Box>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                padding: "0.2rem 0.5rem",
                backgroundColor: costingType==2 ?"#833406":"#faebd7",
                color:costingType==2?"white": "#833406",
                cursor:'pointer'
              }}
              onClick={(ed:any)=>{
                ed.preventDefault();
                ed.stopPropagation();
                history.push(
                  `/costingconfiguration-template/${projectId}/${projectname}/${categoryId}/${categoryGroup}/${configMirror}/${calcId}/${calcSequence}/${config_id}/${2}/${1}`
                );
                setSelectCategoryType(2)}}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                // onClick={(ed:any)=>handleClick(ed)}
                // id="demo-customized-button"
                // aria-controls={open ? "demo-customized-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                
              >
                Scenario
                
              </Box>
            </Box>
          </Box>}
          {searchTerm !== "" ? (
            <>
              {CategorySearchListDC && CategorySearchListDC?.length > 0 && (
                <Accordion
                  expanded={expandedCategory === "DC"}
                  onChange={handleCategoryChange("DC")}
                  sx={{ width: "100%", border: "none" }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{ padding: "0", minHeight: "24px", width: "100%" }}
                    expandIcon={
                      expandedCategory === "DC" ? (
                        <img src={openFolder} style={{ height: "1.2rem" }} />
                      ) : (
                        <FolderIcon
                          sx={{ fontSize: "1.5rem", color: "primary.main" }}
                        />
                      )
                    }
                  >
                    <Box
                      sx={{
                        marginLeft: "0.3rem",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          width: "100%",
                          lineHeight: "1.1",
                          color: "text.primary",
                        }}
                      >
                        DC
                      </Typography>
                      {categoryCount?.dc_count !== undefined && (
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            padding: "0 2px",
                            color: "text.primary",
                          }}
                        >
                          {countLoader ? (
                            <CircularProgress size={17} />
                          ) : (
                            `(${categoryCount?.dc_count})`
                          )}
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {SearchProductLoader ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: "0.6rem",
                          borderLeft: "1px solid",
                          borderColor: "primary.main",
                        }}
                      >
                        {CategorySearchListDC &&
                          CategorySearchListDC?.filter(
                            (item: any) => item?.costing_type === "DC"
                          ).map((item: any, index: any) => {
                            return (
                              <Box
                                key={index}
                                // ref={(el) => (itemRefs.current[item.id] = el)}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor:
                                    item?.id === categoryId
                                      ? "primary.main"
                                      : "",
                                  color:
                                    item?.id === categoryId
                                      ? "white"
                                      : "text.primary",
                                  borderRadius:
                                    item?.id === categoryId ? "5px" : "",
                                  marginBottom: "3px",
                                  paddingRight: "0.2rem",
                                }}
                              >
                                
                                <Typography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "1rem",
                                    columnGap: "2px",
                                    cursor: "pointer",
                                    width: "100%",
                                    padding: "3px 0",
                                  }}
                                  onClick={(e: any) => {
                                    if (!loading) {
                                      CategoryHandler(item);
                                    }
                                  }}
                                >
                                  {" "}
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    --
                                  </span>
                                  <span
                                    style={{
                                      lineHeight: "1.2",
                                      width: "85%",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Typography
                                      title={item?.commodity_name}
                                      sx={{
                                        fontSize: "0.875rem",
                                        width: { lg: "9rem", xl: "7rem" },
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.commodity_name}
                                    </Typography>
                                  </span>{" "}
                                </Typography>
                                <Box
                                  sx={{
                                    // display: "grid",
                                    // gridTemplateColumns: "1fr 1fr",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    gap: "0.2rem",
                                    // alignItems: 'center'
                                  }}
                                >
                                  
                                  {item?.without_link_mapped_count > 0 && (
                                  <Typography
                                    title="Without Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                      color:"#833406"
                                    }}
                                    onClick={() => InfoModelHandler(item, "delinked",selectCategoryType)}
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.without_link_mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                  {item?.mapped_count > 0 && (
                                    <Typography
                                      title="Mapped Details"
                                      sx={{
                                        fontSize: "0.875rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => InfoModelHandler(item, "linked", selectCategoryType)}
                                    >
                                      (
                                      <span
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {item?.mapped_count}
                                      </span>
                                      )
                                    </Typography>
                                  )}

                                  {item?.is_validated && (
                                    <ShareIcon
                                      sx={{ cursor: "pointer" }}
                                      fontSize="small"
                                      onClick={() => {
                                        shareModal.open(item);
                                      }}
                                    />
                                  )}
                                   
                                  <Box sx={{ textAlign: "center" }}>
                                    <CheckBoxValidation
                                      item={item}
                                      permission={permission}
                                      loadingg={loading}
                                    />
                                  </Box>
                                  {/* <CostingCategoryMenuItems
                                  getTreeData={getTreeData}
                                  item={item}
                                  premissionData={premissionData}
                                  ParentCount={ParentCount}
                                  setParentCount={setParentCount}
                                /> */}
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {CategorySearchListBOP && CategorySearchListBOP?.length > 0 && (
                <Accordion
                  expanded={expandedCategory === "BOP"}
                  onChange={handleCategoryChange("BOP")}
                  sx={{ width: "100%", border: "none" }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{ padding: "0", minHeight: "24px", width: "100%" }}
                    expandIcon={
                      expandedCategory === "BOP" ? (
                        <img src={openFolder} style={{ height: "1.2rem" }} />
                      ) : (
                        <FolderIcon
                          sx={{ fontSize: "1.5rem", color: "primary.main" }}
                        />
                      )
                    }
                  >
                    <Box
                      sx={{
                        marginLeft: "0.3rem",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          width: "100%",
                          lineHeight: "1.1",
                          color: "text.primary",
                        }}
                      >
                        BOP
                      </Typography>
                      {categoryCount?.bop_count !== undefined && (
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            padding: "0 2px",
                            color: "text.primary",
                          }}
                        >
                          {countLoader ? (
                            <CircularProgress size={17} />
                          ) : (
                            `(${categoryCount?.bop_count})`
                          )}
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {SearchProductLoader ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: "0.6rem",
                          borderLeft: "1px solid",
                          borderColor: "primary.main",
                        }}
                      >
                        {CategorySearchListBOP &&
                          CategorySearchListBOP?.filter(
                            (item: any) => item?.costing_type === "BOP"
                          ).map((item: any, index: any) => {
                            const counts = [
                              item?.commodity_count
                                ? `C: ${item?.commodity_count}`
                                : null,
                              item?.process_count
                                ? `P: ${item?.process_count}`
                                : null,
                              item?.parameter_count
                                ? `CM: ${item?.parameter_count}`
                                : null,
                            ]
                              .filter(Boolean)
                              .join(" | ");
                            return (
                              <Box
                                key={index}
                                // ref={(el) => (itemRefs.current[item.id] = el)}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor:
                                    item?.id === categoryId
                                      ? "primary.main"
                                      : "",
                                  color:
                                    item?.id === categoryId
                                      ? "white"
                                      : "text.primary",
                                  borderRadius:
                                    item?.id === categoryId ? "5px" : "",
                                  marginBottom: "3px",
                                  paddingRight: "0.2rem",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "1rem",
                                    columnGap: "2px",
                                    cursor: "pointer",
                                    padding: "3px 0",
                                    width: "100%",
                                  }}
                                  onClick={(e: any) => {
                                    CategoryHandler(item);
                                  }}
                                >
                                  {" "}
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    --
                                  </span>
                                  <span
                                    style={{
                                      lineHeight: "1.2",
                                      width: "85%",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Typography
                                      title={item?.commodity_name}
                                      sx={{
                                        fontSize: "0.875rem",
                                        width: { lg: "9rem", xl: "10rem" },
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.commodity_name}
                                    </Typography>
                                  </span>{" "}
                                </Typography>
                                <Box
                                  sx={{
                                    // display: "grid",
                                    // gridTemplateColumns: "1fr 1fr",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    gap: "0.2rem",
                                    // alignItems: 'center'
                                  }}
                                >
                                  
                                  {item?.without_link_mapped_count > 0 && (
                                  <Typography
                                    title="Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                      color:"#833406"
                                    }}
                                    onClick={() => InfoModelHandler(item, "delinked", selectCategoryType)}
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.without_link_mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                  {item?.mapped_count > 0 && (
                                    <Typography
                                      title="Mapped Details"
                                      sx={{
                                        fontSize: "0.875rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => InfoModelHandler(item, "linked", selectCategoryType)}
                                    >
                                      (
                                      <span
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {item?.mapped_count}
                                      </span>
                                      )
                                    </Typography>
                                  )}
                                    {item?.is_validated && (
                                    <ShareIcon
                                      sx={{ cursor: "pointer" }}
                                      fontSize="small"
                                      onClick={() => {
                                        shareModal.open(item);
                                      }}
                                    />
                                  )}
                                  <Box sx={{ textAlign: "center" }}>
                                    <CheckBoxValidation
                                      item={item}
                                      permission={permission}
                                      loadingg={loading}
                                    />
                                  </Box>
                                  {/* <CostingCategoryMenuItems getTreeData={getTreeData} item={item} premissionData={premissionData} ParentCount={ParentCount} setParentCount={setParentCount} /> */}
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ) : (
            <>
              <Accordion
                expanded={expandedCategory === "DC"}
                onChange={handleCategoryChange("DC")}
                sx={{ width: "100%", border: "none" }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{ padding: "0", minHeight: "24px", width: "100%" }}
                  expandIcon={
                    expandedCategory === "DC" ? (
                      <img src={openFolder} style={{ height: "1.2rem" }} />
                    ) : (
                      <FolderIcon
                        sx={{ fontSize: "1.5rem", color: "primary.main" }}
                      />
                    )
                  }
                >
                  <Box
                    sx={{
                      marginLeft: "0.3rem",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        width: "100%",
                        lineHeight: "1.1",
                        color: "text.primary",
                      }}
                    >
                      DC
                    </Typography>
                    {categoryCount?.dc_count !== undefined && (
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          padding: "0 2px",
                          color: "text.primary",
                        }}
                      >
                        {countLoader ? (
                          <CircularProgress size={17} />
                        ) : (
                          `(${categoryCount?.dc_count})`
                        )}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {ProductLoader ? (
                    <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "0.6rem",
                        borderLeft: "1px solid",
                        borderColor: "primary.main",
                        height: "75vh",
                      }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      {CategoryDCList &&
                        CategoryDCList?.map((item: any, index: any) => {
                          return (
                            <Box
                              key={index}
                              // ref={(el) => (itemRefs.current[item.id] = el)}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor:
                                  item?.id === categoryId ? "primary.main" : "",
                                color:
                                  item?.id === categoryId
                                    ? "white"
                                    : "text.primary",
                                borderRadius:
                                  item?.id === categoryId ? "5px" : "",
                                marginBottom: "3px",
                                paddingRight: "0.2rem",
                                pointerEvents: loading ? "none" : "inherit",
                                cursor: loading ? "default" : "pointer",
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1rem",
                                  columnGap: "2px",
                                  cursor: "pointer",
                                  width: "100%",
                                  padding: "3px 0",
                                  pointerEvents: loading ? "none" : "inherit",
                                }}
                                onClick={(e: any) => {
                                  CategoryHandler(item);
                                }}
                              >
                                {" "}
                                <span style={{ whiteSpace: "nowrap" }}>--</span>
                                <span
                                  style={{
                                    lineHeight: "1.2",
                                    width: "85%",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Typography
                                    title={item?.commodity_name}
                                    sx={{
                                      fontSize: "0.875rem",
                                      width: { lg: "9rem", xl: "10rem" },
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.commodity_name}
                                  </Typography>
                                </span>
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: "0.2rem",
                                  alignItems: "center",
                                  pointerEvents: loading ? "none" : "inherit",
                                  // background:"red",
                                  // width:"25rem"
                                }}
                              >
                              
                                {item?.without_link_mapped_count > 0 && (
                                  <Typography
                                    title="Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                      color:"#833406"
                                    }}
                                    onClick={() => InfoModelHandler(item, "delinked", selectCategoryType)}
                                    
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.without_link_mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                {item?.mapped_count > 0 && (
                                  <Typography
                                    title="Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                      
                                    }}
                                    onClick={() => InfoModelHandler(item, "linked", selectCategoryType)}
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                  {item?.is_validated && (
                                    <ShareIcon
                                      sx={{ cursor: "pointer" }}
                                      fontSize="small"
                                      onClick={() => {
                                        shareModal.open(item);
                                      }}
                                    />
                                  )}

                                <Box
                                  sx={{
                                    textAlign: "center",
                                    pointerEvents: loading ? "none" : "inherit",
                                  }}
                                >
                                  <CheckBoxValidation
                                    item={item}
                                    permission={permission}
                                    loadingg={loading}
                                  />
                                </Box>
                                {/* <CostingCategoryMenuItems
                                  getTreeData={getTreeData}
                                  item={item}
                                  premissionData={premissionData}
                                  ParentCount={ParentCount}
                                  setParentCount={setParentCount}
                                /> */}
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedCategory === "BOP"}
                onChange={handleCategoryChange("BOP")}
                sx={{ width: "100%", border: "none" }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{ padding: "0", minHeight: "24px", width: "100%" }}
                  expandIcon={
                    expandedCategory === "BOP" ? (
                      <img src={openFolder} style={{ height: "1.2rem" }} />
                    ) : (
                      <FolderIcon
                        sx={{ fontSize: "1.5rem", color: "primary.main" }}
                      />
                    )
                  }
                >
                  <Box
                    sx={{
                      marginLeft: "0.3rem",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        lineHeight: "1.1",
                        color: "text.primary",
                        fontSize: "1rem",
                      }}
                    >
                      BOP
                    </Typography>
                    {categoryCount?.bop_count !== undefined && (
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          padding: "0 2px",
                          color: "text.primary",
                        }}
                      >
                        {countLoader ? (
                          <CircularProgress size={17} />
                        ) : (
                          `(${categoryCount?.bop_count})`
                        )}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {ProductLoader ? (
                    <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "0.6rem",
                        borderLeft: "1px solid",
                        borderColor: "primary.main",
                        height: "76vh",
                      }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      {CategoryBOPList &&
                        CategoryBOPList?.map((item: any, index: any) => {
                          const counts = [
                            item?.commodity_count
                              ? `C: ${item?.commodity_count}`
                              : null,
                            item?.process_count
                              ? `P: ${item?.process_count}`
                              : null,
                            item?.parameter_count
                              ? `CM: ${item?.parameter_count}`
                              : null,
                          ]
                            .filter(Boolean)
                            .join(" | ");
                          return (
                            <Box
                              key={index}
                              // ref={(el) => (itemRefs.current[item.id] = el)}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor:
                                  item?.id === categoryId ? "primary.main" : "",
                                color:
                                  item?.id === categoryId
                                    ? "white"
                                    : "text.primary",
                                borderRadius:
                                  item?.id === categoryId ? "5px" : "",
                                marginBottom: "3px",
                                paddingRight: "0.2rem",
                                pointerEvents: loading ? "none" : "inherit",
                                cursor: loading ? "default" : "pointer",
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1rem",
                                  columnGap: "2px",
                                  cursor: "pointer",
                                  padding: "3px 0",
                                  width: "100%",
                                }}
                                onClick={(e: any) => {
                                  CategoryHandler(item);
                                }}
                              >
                                {" "}
                                <span style={{ whiteSpace: "nowrap" }}>--</span>
                                <span
                                  style={{
                                    lineHeight: "1.2",
                                    width: "85%",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Typography
                                    title={item?.commodity_name}
                                    sx={{
                                      fontSize: "0.875rem",
                                      width: { lg: "9rem", xl: "10rem" },
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.commodity_name}
                                  </Typography>
                                </span>{" "}
                              </Typography>
                              <Box
                                sx={{
                                  // display: "grid",
                                  // gridTemplateColumns: "1fr 1fr",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "0.2rem",
                                  // alignItems: "center",
                                }}
                              >
                              
                                 {item?.without_link_mapped_count > 0 && (
                                  <Typography
                                    title="Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                      color:"#833406"
                                    }}
                                    onClick={() => InfoModelHandler(item, "delinked", selectCategoryType)}
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.without_link_mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                {item?.mapped_count > 0 && (
                                  <Typography
                                    title="Mapped Details"
                                    sx={{
                                      fontSize: "0.875rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => InfoModelHandler(item, "linked", selectCategoryType)}
                                  >
                                    (
                                    <span
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item?.mapped_count}
                                    </span>
                                    )
                                  </Typography>
                                )}
                                  {item?.is_validated && (
                                    <ShareIcon
                                      sx={{ cursor: "pointer" }}
                                      fontSize="small"
                                      onClick={() => {
                                        shareModal.open(item);
                                      }}
                                    />
                                  )}
                                <Box sx={{ textAlign: "center" }}>
                                  <CheckBoxValidation
                                    item={item}
                                    permission={permission}
                                    loadingg={loading}
                                  />
                                </Box>
                                {/* <CostingCategoryMenuItems
                                  getTreeData={getTreeData}
                                  item={item}
                                  premissionData={premissionData}
                                  ParentCount={ParentCount}
                                  setParentCount={setParentCount}
                                /> */}
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          height: "89vh",
          zIndex: 1,
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ height: "100%", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mb: 0.2,
                justifyContent: "space-between",
                marginTop: "-2.2rem",
                position: "fixed",
                width: { lg: "42%", xl: "41.4%" },
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: { lg: "3px", xl: 1 },
                  marginLeft: 1,
                  alignItems: "center",
                }}
              >
                Hide :
                <Box display={"flex"} alignItems={"center"}>
                  <Checkbox
                    checked={hideCommodity}
                    icon={<RadioButtonUncheckedIcon />} // Unchecked icon as radio button
                    checkedIcon={<RadioButtonCheckedIcon />}
                    onChange={handleToggleCommodity}
                    color="primary"
                    size="small"
                    sx={{
                      padding: "0.2rem",
                    }}
                  />
                  <Typography sx={{ fontSize: "1rem" }}>Commodity</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Checkbox
                    checked={hideProcess}
                    icon={<RadioButtonUncheckedIcon />} // Unchecked icon as radio button
                    checkedIcon={<RadioButtonCheckedIcon />}
                    onChange={handleToggleProcess}
                    color="primary"
                    size="small"
                    sx={{
                      padding: "0.2rem",
                    }}
                  />
                  <Typography sx={{ fontSize: "1rem" }}>Process</Typography>
                </Box>
              </Box>

            

              {categoryInfo && (
                <CostingCategoryTopHeaderMenu
                  getTreeData={getTreeData}
                  item={categoryInfo}
                  premissionData={premissionData}
                  ParentCount={ParentCount}
                  setParentCount={setParentCount}
                  showMenuItemsDirectly={true}
                  lockingLoader={isLoading || RMLoading || PSLoading || OHLoading}
                />
              )}

              <Box>
                {permission?.includes("U") && !categoryInfo?.lock ? (
                  <Badge
                    badgeContent={bucketCount}
                    color="error"
                    sx={{
                      "& .MuiBadge-badge": {
                        minWidth: "16px", // Change these values to make the badge smaller
                        height: "16px",
                        fontSize: "10px", // Adjust font size as needed
                        padding: "0 4px", // Optional: Adjust padding for the content
                      },
                    }}
                  >
                    <img
                      src={BucketIcon}
                      style={{
                        cursor: "pointer",
                        height: "1.5rem",
                        width: "auto",
                      }}
                      onClick={() => {
                        bucketOpen.open();
                      }}
                    />
                  </Badge>
                ) : (
                  <Badge
                    badgeContent={bucketCount}
                    //  color="error"
                    sx={{
                      "& .MuiBadge-badge": {
                        background: "#d7ded7",
                        color: "grey",
                        minWidth: "16px", // Change these values to make the badge smaller
                        height: "16px",
                        fontSize: "10px", // Adjust font size as needed
                        padding: "0 4px", // Optional: Adjust padding for the content
                      },
                    }}
                  >
                    <img
                      src={BucketIcon}
                      style={{ cursor: "default", width: "1.5rem" }}
                    />
                  </Badge>
                )}
              </Box>

              {!categoryInfo?.mapped_status && permission?.includes("D") && (
                <Box>
                  <LoadingButton
                    disabled={categoryInfo?.lock === false ? false : true}
                    color="error"
                    variant="outlined"
                    size="small"
                    sx={{
                      display: { lg: "none", xl: "inline" },
                      whiteSpace: "nowrap",
                      padding: "0.3rem 0.5rem",
                      lineHeight: "1",
                    }}
                    onClick={() => clearFetchedCostModels()}
                  >
                    Clear CM
                  </LoadingButton>
                </Box>
              )}
              {!categoryInfo?.mapped_status && permission?.includes("D") && (
                <IconButton
                  sx={{
                    display: { lg: "contents", xl: "none" },
                    padding: "0",
                    cursor: "pointer",
                    color: "red",
                    ":hover": { color: "red" },
                  }}
                  onClick={() => clearFetchedCostModels()}
                >
                  {" "}
                  <HighlightOffIcon titleAccess="Clear Cost Model" />
                </IconButton>
              )}

              {(categoryInfo?.show_perform_costing_project ||
                categoryInfo?.show_perform_costing_category) && (
                <>
                  {AUTH.isSuperUser ? (
                    <LoadingButton
                      disabled={permission?.includes("U") ? false : true}
                      variant="outlined"
                      size="small"
                      sx={{
                        whiteSpace: "nowrap",
                        padding: "0.3rem 0.5rem",
                        lineHeight: "1",
                      }}
                      loading={isCostSync}
                      onClick={handleClickMenu} // Open the menu on button click
                    >
                      Perform Costing
                    </LoadingButton>
                  ) : userProfile?.[0]?.is_project_admin ? (
                    <>
                      {categoryInfo?.show_perform_costing_category && (
                        <LoadingButton
                          disabled={permission?.includes("U") ? false : true}
                          variant="outlined"
                          size="small"
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0.3rem 0.5rem",
                            lineHeight: "1",
                          }}
                          onClick={() => {
                            setPcType("Category");
                            handlePerformCosting();
                          }}
                          loading={isCostSync}
                        >
                          Perform Costing
                        </LoadingButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {/* )
               }     */}

              <Menu
                anchorEl={anchorEl} // Element to anchor the menu
                open={Boolean(anchorEl)} // Open the menu if anchorEl is not null
                onClose={handleCloseMenu} // Close the menu when clicking outside
              >
                {categoryInfo?.show_perform_costing_project && (
                  <MenuItem onClick={() => handleMenuItemClick("Project")}>
                    For Project
                  </MenuItem>
                )}
                {categoryInfo?.show_perform_costing_category && (
                  <MenuItem onClick={() => handleMenuItemClick("Category")}>
                    For Category
                  </MenuItem>
                )}
              </Menu>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                {(categoryInfo?.sync === false &&
                  categoryInfo?.mapped_status === true && costingType != 2) && (
                    <Tooltip title="Sync">
                      <SyncIcon
                        onClick={() =>
                          permission?.includes("U") ? handlesync() : null
                        }
                        sx={{
                          color: permission?.includes("U") ? "red" : "gray",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  )}
              </Box>
              <Divider
                orientation="vertical"
                variant="inset"
                sx={{ margin: "3px 0" }}
                flexItem
              />
              
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                backgroundColor: "primary.light",
              }}
            >
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 0.75rem",
                  fontWeight: "bold",
                  width: { lg: "24rem", xl: "28rem" },
                  textAlign: "left",
                }}
              >
                {!collapseLoading ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      if (!isExpanded) {
                        // sessionStorage.setItem(
                        //   "Costingconfigurationnode",
                        //   JSON.stringify([
                        //     "Parent",
                        //     "1Material",
                        //     "2RawMaterial",
                        //     "3BoughtOutPart",
                        //     "4Process",
                        //     "5Labour",
                        //     "6Machine",
                        //     "7Tool",
                        //     "8Setup",
                        //     "9Overheads",
                        //   ])
                        // );
                        sessionStorage.setItem(
                          "Costingconfigurationnode",
                          JSON.stringify(predefinedNodeItems)
                        );

                        setWolCounter((prev: any) => prev + 1);
                      } else {
                        sessionStorage.removeItem("Costingconfigurationnode");
                        sessionStorage.setItem(
                          "Costingconfigurationnode",
                          JSON.stringify([
                            "Parent",
                            "1Material",
                            "4Process",
                            "9Overheads",
                          ])
                        );
                      }
                      toggleExpandCollapse();
                      setWolCounter((prev: any) => prev + 1);
                    }}
                  >
                    {isExpanded ? "Collapse All" : "Expand All"}
                  </span>
                ) : (
                  <CircularProgress size="20px" />
                )}
              </Box>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 0.2rem",
                  fontWeight: "bold",
                  width: "3.5rem",
                  textAlign: "center",
                  // borderRight: "1px solid #007fff1f",
                }}
              >
                Qty
              </Box>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 0.2rem",
                  fontWeight: "bold",
                  width: { lg: "12rem", xl: "16rem" },
                  textAlign: "center",
                  // borderRight: "1px solid #007fff1f",
                }}
              >
                Var/For
              </Box>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 1rem",
                  fontWeight: "bold",
                  width: "7rem",
                  textAlign: "center",
                  // cursor: "pointer",
                }}
                // onClick={() => {
                //  outputClicked()
                // }}
              >
                Output
              </Box>
            </Box>
            <Box
              sx={{
                height: { lg: "84vh", xl: "87vh" },
                marginLeft: "0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!isLoading && AllHierarchy ? (
                <>
                  <Accordion
                    expanded={treeexpanded === "panel1"}
                    sx={{ border: "none" }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={
                        treeexpanded === "panel1" ? (
                          <img
                            src={openFolder}
                            style={{
                              height: width > 1369 ? "1.05rem" : "1.3rem",
                            }}
                          />
                        ) : (
                          <FolderIcon
                            sx={{
                              fontSize: { lg: "1.5rem", xl: "1.4rem" },
                              color: "primary.main",
                            }}
                          />
                        )
                      }
                      sx={{
                        minHeight: "32px",
                        padding: "0 0.2rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            marginLeft: "0.5rem",
                            width: { lg: "47%", xl: "48%" },
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography sx={{ fontSize: "1rem" }}>
                            {AllHierarchy?.[0]?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: "0.5rem",
                            width: { lg: "53%", xl: "52%" },
                          }}
                        >
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: "4rem",
                              textTransform: "none",
                              textAlign: "center",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              borderRight: "1px solid #007fff1f",
                            }}
                          >
                            <Typography sx={{ fontSize: "1rem" }}>
                              {AllHierarchy?.[0]?.calc_count}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: { lg: "12rem", xl: "16rem" },
                              textTransform: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              borderRight: "1px solid #007fff1f",
                              color: "green",
                              cursor: "pointer",
                            }}
                          >
                            <Typography sx={{ fontSize: "1rem" }}>
                              {AllHierarchy?.[0]?.alias}{" "}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: "5rem",
                              textTransform: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "1rem" }}>
                              {AllHierarchy?.[0]?.test_input}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ marginLeft: "1rem" }}>
                        {Array.isArray(RawMaterialHierarchy) &&
                          RawMaterialHierarchy?.length > 0 &&
                          RawMaterialHierarchy?.map((item: any) => {
                            return (
                              <>
                                <CostingTreeTemplateFetchedData
                                  setHierarchyCount={setHierarchyCount}
                                  HierarchyData={item && item}
                                  type="raw_material"
                                  onSelectCallback={onSelect}
                                  setSelectedTreeData={getCalcData}
                                  setCounterApiCall={setCounterApiCall}
                                  useraction={"CRUD"}
                                  category={categoryInfo}
                                  getCategoryInfo={getCategoryInfo}
                                  getTreeData={getTreeDataWOL}
                                  setParentCount={setParentCount}
                                  selectedTreeData={selectedTreeData}
                                  categoryList={
                                    expandedCategory === "DC"
                                      ? CategoryDCList
                                      : CategoryBOPList
                                  }
                                  setAddFectchCounter={setAddFectchCounter}
                                />
                              </>
                            );
                          })}
                        {categoryGroup === "dc" &&
                          Array.isArray(ProcessHierarchy) &&
                          ProcessHierarchy?.length > 0 &&
                          ProcessHierarchy?.map((item: any) => {
                            return (
                              <>
                                <CostingTreeTemplateFetchedData
                                  setHierarchyCount={setHierarchyCount}
                                  type="process"
                                  HierarchyData={item && item}
                                  onSelectCallback={onSelect}
                                  setSelectedTreeData={getCalcData}
                                  setCounterApiCall={setCounterApiCall}
                                  useraction={"CRUD"}
                                  category={categoryInfo}
                                  getTreeData={getTreeDataWOL}
                                  setParentCount={setParentCount}
                                  selectedTreeData={selectedTreeData}
                                  categoryList={
                                    expandedCategory === "DC"
                                      ? CategoryDCList
                                      : CategoryBOPList
                                  }
                                  setAddFectchCounter={setAddFectchCounter}
                                />
                              </>
                            );
                          })}
                        {Array.isArray(OverheadHierarchy) &&
                          OverheadHierarchy?.length > 0 &&
                          OverheadHierarchy?.map((item: any) => {
                            return (
                              <>
                                <CostingTreeTemplateFetchedData
                                  setHierarchyCount={setHierarchyCount}
                                  HierarchyData={item && item}
                                  type="overhead"
                                  onSelectCallback={onSelect}
                                  setSelectedTreeData={getCalcData}
                                  setCounterApiCall={setCounterApiCall}
                                  useraction={"CRUD"}
                                  category={categoryInfo}
                                  getTreeData={getTreeDataWOL}
                                  setParentCount={setParentCount}
                                  selectedTreeData={selectedTreeData}
                                  categoryList={
                                    expandedCategory === "DC"
                                      ? CategoryDCList
                                      : CategoryBOPList
                                  }
                                  setAddFectchCounter={setAddFectchCounter}
                                />
                              </>
                            );
                          })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            
            {(dataType === "calculator" ||
              (selectedTreeData?.type === "calculator" &&
                sessionStorage.getItem("SelectedTreeItem"))) &&
            Object.keys(selectedTreeData)?.length > 0 ? (
              <>
              
              <CreateCostCalculatorView
                selectedTreeData={selectedTreeData && selectedTreeData}
                setCounterApiCall={setCounterApiCall}
                getTreeData={getTreeDataWOL}
                setDataType={setDataType}
                getBucketList={getBucketList}
                categoryInfo={categoryInfo}
                bucketRefreshCounter={bucketRefreshCounter}
                outputClicked={outputClicked}
              />
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <img src={calculator} style={{ width: "3rem" }} /> <br />
                  Click on the cost model from hierarchy on the left side
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {bucketOpen.isOpen && (
        <BucketViewModal
          isOpen={bucketOpen.isOpen}
          onCloseModal={bucketOpen.close}
          getBucketList={getBucketList}
          data={bucketList}
          setCounterApiCall={setCounterApiCall}
          setBucketRefreshCounter={setBucketRefreshCounter}
        />
      )}

      {openPerformCostingModal.isOpen && (
        <>
          {
            pcType === "Category" ? (
              <PerformCostingModal
                isOpen={openPerformCostingModal.isOpen}
                onCloseModal={openPerformCostingModal.close}
                headingName={categoryInfo?.commodity_name}
                type="Category"
                category_id={categoryId}
                project_id={projectId}
                setParentCount={setParentCount}
                setSyncCount={setSyncCount}
              />
            ) : (
              <PerformCostingModal
                isOpen={openPerformCostingModal.isOpen}
                onCloseModal={openPerformCostingModal.close}
                headingName={projectname}
                type="Project"
                category_id={null}
                project_id={projectId}
                setParentCount={setParentCount}
                setSyncCount={setSyncCount}
              />
            )
            //   <PerformCostingProjectModal
            //   isOpen={openPerformCostingModal.isOpen}
            //   onCloseModal={openPerformCostingModal.close}
            //   project_id={projectId}
            //   headingName={projectname}

            // />
          }
        </>
      )}

      {openSyncModel.isOpen && (
        <CostingConfigSyncModal
          isOpen={openSyncModel.isOpen}
          onCloseModal={openSyncModel.close}
          data={openSyncModel?.propsId}
          category_id={categoryId}
          setParentCount={setParentCount}
          ParentCount={ParentCount}
          setSyncCount={setSyncCount}
        />
      )}
       {shareModal.isOpen &&
        <ShareInstanceModal isOpen={shareModal.isOpen} onCloseModal={shareModal.close} type={shareModal.propsId} setParentCount={setParentCount}/>      }
    </Box>
  );
}
