import React, {  } from "react";
import Modal from "@mui/material/Modal";
import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";
import {
  InputContentType,
  BOM_CALCULATOR_INPUT_TYPE,
} from "../../Configuration/Costing/calculator.constant";
import {
  Box,
  Typography,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import styles from "./bomcalculator.module.scss";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import DatabasecalcTable from "./DatabaseCalcTable";

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  minHeight: "30vh",
  maxHeight: "54vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface BOMConfigCalculatorModalProps {
  isOpen: any;
  onCloseModal: any;
  id: any;
  inputData: any;
  setCounter: any;
  parentClose: any;
  categoryName: any;
  setResStatus:any;
  typee?: any;
}

const inputKeysToOmit: Record<number, string[]> = {
  [BOM_CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
    "formula",
    "constant",
    "user_input",
  ],
  [BOM_CALCULATOR_INPUT_TYPE.CONSTANT]: ["formula", "parameter"],

  [BOM_CALCULATOR_INPUT_TYPE.FORMULA]: ["constant", "parameter"],
  [BOM_CALCULATOR_INPUT_TYPE.USER_INPUT]: ["constant", "parameter", "formula"],
};
interface BOMCalculatorInputFromApi {
  id: ID;
  content_type: InputContentType;
  name: string;
  /** string used to refere the input in the formula */
  alias: string;

  parameter?: ID;
  table_element?: ID;

  attribute?: string | any;

  formula?: string;
  constant?: number;
  user_input?: string;
  calculator_category?: number;
  volume?: number;
  calculator: ID;
  image_url?: string;
  test_input?: number;
  result?: number;
}

export const finalResultContext = React.createContext<any>(null);

const DatabaseCalculatorConfig = (props: BOMConfigCalculatorModalProps) => {
  const {
    isOpen,
    onCloseModal,
    id,
    inputData,
    setCounter,
    parentClose,
    categoryName,
    setResStatus,
    typee
  } = props;
  
  // const [useraction,setuseraction]=useState<any>(null)
  const { productId, categoryId, validationId } = useRouteParams<any>();
  const [data, setdata] = React.useState<any>([]);
  const [finalResult,setFinalResult]=React.useState<any>(0)

  const getData = () => {
    let url = typee === "Tool"? "/api/db/tool_cost_entry/":"/api/db/material_cost_entry/"
    API.get(
      url,
      {
        [typee === "Tool"?"tool_cost":"material_cost"]:typee === "Tool"? inputData?.idd:inputData?.id,
      },
      0
    )
      .then((res: any) => {
        setdata(res.data?.response);
        setFinalResult(res.data?.result)
        setResStatus(res.data?.status)
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleCloseModal = () => {
    setCounter(categoryName);
    // parentClose();
    onCloseModal();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Calculation (Final Result: {finalResult || 0})
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <div className={styles.tableWrapper}>
            <finalResultContext.Provider value={finalResult}>
            <DatabasecalcTable data={data} inputData={inputData} getData={getData} type={typee} />
            </finalResultContext.Provider>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DatabaseCalculatorConfig;
