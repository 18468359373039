import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Paper,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Typography,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Chip,
  MenuItem,
} from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../../Redux/Services/admin.service";
import ReplayIcon from "@mui/icons-material/Replay";
import RViewerJS from "viewerjs-react";
import { CustomMultiSelect } from "./CustomMultiSelect";

export interface RowData {
  id: number;
  item_type: string | null;
  length: any;
  width: any;
  ocr: any;
  qty: any;
  image: string | null;
  location: string | null;
}

// const dummyData: RowData[] = [
//   { id: 1, name: "Row 1",image:"jkhkjhkjh", description: "Description 1" },
//   { id: 2, name: "Row 2",image:"jkhkjhkjh", description: "Description 2" },
//   { id: 3, name: "Row 3",image:"jkhkjhkjh", description: "Description 3" },
//   { id: 4, name: "Row 4",image:"jkhkjhkjh", description: "Description 4" },
//   { id: 5, name: "Row 5",image:"jkhkjhkjh", description: "Description 5" },
//   { id: 6, name: "Row 6",image:"jkhkjhkjh", description: "Description 6" },
//   { id: 7, name: "Row 7",image:"jkhkjhkjh", description: "Description 7" },
//   { id: 8, name: "Row 8",image:"jkhkjhkjh", description: "Description 8" },
// ];

const BOMRobotoflowFetchDataTable: any = (props: any) => {
  const { tableId, onCloseModal } = props;
  const { vault } = useRouteParams<any>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [tableDataTop, setTableDataTop] = useState<RowData[]>([]);
  const [tableDataBottom, setTableDataBottom] = useState<RowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getData = () => {
    if (activeTab === 0) {
      return tableDataTop;
    } else {
      return tableDataBottom;
    }
  };

  const getTableData = () => {
    setLoading(true);
    API.post(`/xcpep/pcb_automation_via_roboflow/${vault}/`, {}, {}, 0)
      .then((res: any) => {
        setLoading(false);
        let Topdata: RowData[] = res.data?.TOP.map((item: any, index: any) => {
          return {
            id: item?.unique_id,
            item_type: item.item_type,
            length: item.length,
            width: item.width,
            ocr: item.ocr,
            qty: item.qty,
            image: item.image,
            location: item.location,
          };
        });

        let BOTTOM: RowData[] = res.data?.BOTTOM.map(
          (item: any, index: any) => {
            return {
              id: item?.unique_id,
              item_type: item.item_type,
              length: item.length,
              width: item.width,
              ocr: item.ocr,
              qty: item.qty,
              image: item.image,
              location: item.location,
            };
          }
        );
        setTableDataTop(Topdata);
        setTableDataBottom(BOTTOM);
      })
      .catch((err: any) => {
        ADMIN.toast.error("Could not fetch data, Please try again");
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTableData();
  }, []);

  // Handle checkbox selection/unselection
  const handleSelectRow = (id: number) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id) // Unselect row
          : [...prevSelected, id] // Select row
    );
  };

  // Handle the Fetch button click
  const handleFetchSelectedRows = () => {
    const selectedData = [...tableDataTop, ...tableDataBottom].filter((row) =>
      selectedRows.includes(row.id)
    );
    setFetchLoading(true);
    API.post(
      `/xcpep/create_bulk_pcb_component/${vault}/`,
      {
        bulk_data: selectedData,
        table_id: tableId,
      },
      {},
      0
    )
      .then((res: any) => {
        setFetchLoading(false);
        onCloseModal();
      })
      .catch((err: any) => {
        setFetchLoading(false);
      });
  };

  return (
    <Paper style={{ width: "100%", overflow: "hidden" }}>
      {/* <Box sx={{ margin: "1rem" }}> */}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ marginBottom: "1rem", color: "#007fff" }}
        >
          <Tab
            label={`Top ( ${tableDataTop?.length || "--"} )`}
            sx={{ color: "#007fff" }}
          />
          <Tab
            label={`Bottom ( ${tableDataBottom?.length || "--"} )`}
            sx={{ color: "#007fff" }}
          />
        </Tabs>
        <Box sx={{ width: "80%" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.4rem",
              cursor: "pointer",
              textDecoration: "underline",
              float: "right",
            }}
            onClick={() => getTableData()}
          >
            Retry <ReplayIcon fontSize="small" />
          </Typography>
        </Box>
      </Box>
      {/* </Box> */}
      <TableContainer
        style={{ maxHeight: "400px", overflowY: "auto" }} // Fixed height with scroll
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Select
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                SL No.
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Item type
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Length
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Width
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                OCR
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Qty.
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                }}
              >
                Image
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  textAlign: "left",
                  backgroundColor: "primary.light",
                  width: "20rem",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && getData() ? (
              getData()?.map((row: any, index: any) => (
                <TableRow key={row.id} hover>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.item_type}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.length}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.width}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.ocr}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {row.qty}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {/* @ts-ignore*/}
                    <RViewerJS>
                      <img
                        src={`data:image/jpeg;base64,${row.image}`}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    </RViewerJS>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      textAlign: "left",
                      width: "20rem",
                    }}
                  >
                    {row?.item_type === "Capacitor" && (
                      <CustomMultiSelect
                        key={index}
                        tableDataTop={tableDataTop || []}
                        tableDataBottom={tableDataBottom || []}
                        row={row}
                        getTableData={getTableData}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow hover>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" ,width: "20rem"}}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" ,width: "20rem"}}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" ,width: "20rem"}}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" }}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem", textAlign: "left" ,width: "20rem"}}
                  >
                    {/* Render base64 images */}
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={fetchLoading}
          disabled={selectedRows.length === 0}
          onClick={handleFetchSelectedRows}
          style={{ margin: "10px" }}
        >
          Fetch Selected Rows
        </LoadingButton>
      </Box>
    </Paper>
  );
};

export default BOMRobotoflowFetchDataTable;
