import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FolderIcon from "@mui/icons-material/Folder";
import { AUTH } from "../../Redux/Services/auth.service";
import ChatIcon from "@mui/icons-material/Chat";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <FolderIcon sx={{ fontSize: "1.4rem", color: "primary.main" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

type Group = {
  id: number;
  name: string;
  chats_count: number;
  chats: Chat[];
};

type Chat = {
  chat_id: number;
  chat_name: string;
};

export interface IOtherChatComponentProps {
  setIsEmptyGroup: any;
  reCallgrooup: any;
  setWindowExpanded: any;
  expandedWindow: any;
}

export default function OtherChatComponent(props: IOtherChatComponentProps) {
  const { setIsEmptyGroup, reCallgrooup, setWindowExpanded, expandedWindow } =
    props;
  const { grpId, chatId, userId, tab } = useRouteParams<any>();
  const history = useHistory();
  const [groups, setGroups] = useState<Group[]>([]);
  const [openGroups, setOpenGroups] = useState<number[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "addGroup" | "editGroup" | "addChat" | "editChat"
  >("addGroup");
  const [currentGroupId, setCurrentGroupId] = useState<number | null>(null);
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [hoveredGroupId, setHoveredGroupId] = useState<number | null>(null); // Track hovered group
  const [hoveredChatId, setHoveredChatId] = useState<number | null>(null); // Track hovered chat
  const [userList, setUserList] = useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false | any>("");
  const [expandedUser, setExpandedUser] = React.useState<string | false | any>(
    ""
  );

  React.useEffect(() => {
    setExpandedUser(+userId);
    setExpanded(+grpId);
  }, []);

  const handleChange =
    (panel: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      if (panel) {
        history.push(`/chat/${panel}/0/${userId}/${tab}`);
      }
    };

  const handleChangeUser =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedUser(newExpanded ? panel : false);
      if (panel) {
        fetchGroupsUser(panel);
        history.push(`/chat/0/0/${panel}/${tab}`);
      }
    };

  const getUsersList = () => {
    API.get(
      "/xcpep/get_LLM_search_history/",
      {
        user_list: true,
      },
      0
    )
      .then((res: any) => {
        setUserList(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (AUTH.isSuperUser) {
      getUsersList();
    }
  }, []);

  const fetchGroups = async () => {
    API.get(
      "/xcpep/get_LLM_search_history/",
      { header_list: true, user_id: expandedUser },
      0
    )
      .then((res: any) => {
        setGroups(res.data);
        if (
          grpId == 0 &&
          chatId == 0 &&
          res?.data?.length > 0 &&
          res?.data[0]?.chats?.length > 0
        ) {
          history.push(
            `/chat/${res.data[0].id}/${res.data[0].chats[0]?.chat_id}/${userId}/${tab}`
          );
        }
        if (res?.data?.length > 0) {
          setIsEmptyGroup(false);
        } else {
          setIsEmptyGroup(true);
        }
        setOpenGroups(res.data[0]?.id); // Open all groups initially
      })
      .catch((e: any) => {
        setIsEmptyGroup(true);
      });
  };
  const fetchGroupsUser = async (user: any) => {
    API.get(
      "/xcpep/get_LLM_search_history/",
      { header_list: true, user_id: user },
      0
    )
      .then((res: any) => {
        setGroups(res.data);
        if (
          grpId == 0 &&
          chatId == 0 &&
          res?.data?.length > 0 &&
          res?.data[0]?.chats?.length > 0
        ) {
          history.push(
            `/chat/${res.data[0].id}/${res.data[0].chats[0]?.chat_id}/${userId}/${tab}`
          );
        }
        if (res?.data?.length > 0) {
          setIsEmptyGroup(false);
        } else {
          setIsEmptyGroup(true);
        }
        setOpenGroups(res.data[0]?.id); // Open all groups initially
      })
      .catch((e: any) => {
        setIsEmptyGroup(true);
      });
  };

  const handleAddGroup = async (name: string) => {
    API.post(
      `/xcpep/get_LLM_search_history/`,
      { group_name: name },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Group Added Successfully");
      history.push(`/chat/0/0/${userId}/${tab}`);
      getUsersList();
      fetchGroups();
    });
  };

  const handleEditGroup = async (groupId: number, name: string) => {
    API.put(
      `/xcpep/get_LLM_search_history/${groupId}/`,
      { name: name, group: true },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Group Updated Successfully");
      getUsersList();
      fetchGroups();
    });
  };

  const handleDeleteGroup = async (groupId: number) => {
    API.delete(
      `/xcpep/get_LLM_search_history/${groupId}/`,
      { group: true },
      0
    ).then(() => {
      ADMIN.toast.info("Group Deleted Successfully");
      history.push(`/chat/0/0/${userId}/${tab}`);
      getUsersList();
      fetchGroups();
    });
  };

  const handleAddChat = async (groupId: number, name: string) => {
    API.post(`/xcpep/get_LLM_search_history/`, { group: groupId }, {}, 0).then(
      () => {
        ADMIN.toast.info("Chat Added Successfully");
        history.push(`/chat/${groupId}/0/${userId}/${tab}`);
        getUsersList();
        fetchGroups();
      }
    );
  };

  const handleEditChat = async (chatId: number, name: string) => {
    API.put(
      `/xcpep/get_LLM_search_history/${chatId}`,
      { name: name, chat: true },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Chat Updated Successfully");
      getUsersList();
    });
  };

  const handleDeleteChat = async (chatId: number) => {
    API.delete(
      `/xcpep/get_LLM_search_history/${chatId}/`,
      { chat: true },
      0
    ).then(() => {
      ADMIN.toast.info("Group Deleted Successfully");
      history.push(`/chat/0/0/${userId}/${tab}`);
      getUsersList();
      fetchGroups();
    });
  };

  useEffect(() => {
    getUsersList();
  }, [reCallgrooup]);

  const handleDialogOpen = (
    type: "addGroup" | "editGroup" | "addChat" | "editChat",
    groupId?: number,
    chatId?: number
  ) => {
    setDialogType(type);
    setCurrentGroupId(groupId ?? null);
    setCurrentChatId(chatId ?? null);
    setInputValue("");
    if (type === "editGroup" && groupId) {
      const group = groups.find((g) => g.id === groupId);
      setInputValue(group?.name || "");
    }
    if (type === "editChat" && chatId) {
      const group = groups.find((g) =>
        g.chats.some((c) => c.chat_id === chatId)
      );
      const chat = group?.chats.find((c) => c.chat_id === chatId);
      setInputValue(chat?.chat_name || "");
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = () => {
    switch (dialogType) {
      case "addGroup":
        handleAddGroup(inputValue);
        break;
      case "editGroup":
        if (currentGroupId) handleEditGroup(currentGroupId, inputValue);
        break;
      case "addChat":
        if (currentGroupId) handleAddChat(currentGroupId, inputValue);
        break;
      case "editChat":
        if (currentChatId) handleEditChat(currentChatId, inputValue);
        break;
    }
    handleDialogClose();
  };

  return (
    <div>
      {userList?.map((user: any) => {
        return (
          <Box key={user.user_id}>
            <Accordion
              expanded={expandedUser === user.user_id}
              onChange={handleChangeUser(user.user_id)}
              sx={{ backgroundColor: "transparent" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{
                    backgroundColor: "transparent",
                    minHeight: "24px",
                    padding: "0",
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    sx={{
                      marginLeft: "0.5rem",
                      color: "#1976d2",
                      whiteSpace: "nowrap",
                      maxWidth: "150px",
                    }}
                  >
                    {user.user_name} (G: {user?.group_count} | C:{" "}
                    {user?.chats_count})
                  </Typography>
                </AccordionSummary>
                <Box
                  sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                ></Box>
              </Box>

              <AccordionDetails>
                <Box
                  sx={{
                    marginLeft: "0.7rem",
                    borderLeft: "1px dashed",
                    borderColor: "primary.main",
                  }}
                >
                  {groups?.map((group) => (
                    <Box key={group.id} sx={{ marginLeft: "1rem" }}>
                      <Accordion
                        expanded={expanded === group.id}
                        onChange={handleChange(group.id)}
                        sx={{ backgroundColor: "transparent" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: "5px",
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{
                              backgroundColor: "transparent",
                              minHeight: "24px",
                              padding: "0",
                            }}
                          >
                            <Typography
                              fontWeight="bold"
                              sx={{
                                marginLeft: "0.5rem",
                                color: "#1976d2",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                              }}
                            >
                              {group.name} ({group?.chats_count})
                            </Typography>
                          </AccordionSummary>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="Add Chat"
                              size="small"
                              onClick={() =>
                                handleDialogOpen("addChat", group.id)
                              }
                            >
                              <Add color="primary" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleDialogOpen("editGroup", group.id)
                              }
                            >
                              <Edit color="primary" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteGroup(group.id)}
                            >
                              <Delete sx={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </Box>

                        <AccordionDetails>
                          <Box
                            sx={{
                              marginLeft: "0.7rem",
                              borderLeft: "1px dashed",
                              borderColor: "primary.main",
                            }}
                          >
                            {group?.chats?.map((chat) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  gap: "5px",
                                  paddingLeft: "0.7rem",
                                  backgroundColor:
                                    chatId == chat.chat_id
                                      ? "primary.light"
                                      : "",
                                }}
                              >
                                <Box sx={{ margin: "4px 0", display: "flex" }}>
                                  <ChatIcon />
                                </Box>
                                <Tooltip title={chat.chat_name}>
                                  <ListItemText
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(
                                        `/chat/${group.id}/${chat.chat_id}/${expandedUser}/${tab}`
                                      );
                                    }}
                                    primary={
                                      <Typography
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "150px",
                                        }}
                                      >
                                        {chat.chat_name}
                                      </Typography>
                                    }
                                  />
                                </Tooltip>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDialogOpen(
                                      "editChat",
                                      group.id,
                                      chat.chat_id
                                    )
                                  }
                                >
                                  <Edit color="primary" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteChat(chat.chat_id)}
                                >
                                  <Delete sx={{ color: "red" }} />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}

      {/* {groups && groups?.length>0 && <Box sx={{ display: "flex", gap: 2, marginBottom: 1, mt: 1 }}>
        <Divider
          sx={{
            width: "100%",
            alignItems: "flex-start",
            borderColor: "primary.light",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            sx={{ whiteSpace: "nowrap" }}
            startIcon={<Add />}
            onClick={() => handleDialogOpen("addGroup")}
            disabled={grpId == 0 || userId == 0 ? true : false}
          >
            Add Group
          </Button>
        </Divider>
      </Box>} */}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogType === "addGroup"
            ? "Add Group"
            : dialogType === "editGroup"
            ? "Edit Group"
            : dialogType === "addChat"
            ? "Add Chat"
            : "Edit Chat"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
