import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Backdrop,
  Chip,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddLabourModal.module.scss";

import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { API } from "../../../api-services";
import { memo, useState } from "react";
import LabourFlagS3Upload from "./LabourFlagS3Upload";
import ErrorModalFilesMail from "../../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import LabourFlagS3UploadFetch from "./LabourFlagS3UploadFetch";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  labour_type: yup.string().required("Please enter Labour type"),
  commodity: yup.string().required("Please enter category"),
  description: yup.string().required("Please enter Description"),

  labour_code: yup.string().required("Please enter Labour code "),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  type?: any;
  getRevisionData?: any;
  setCounter: any;
  swalalterHandlerAdd: any;
}

function AddLabourModal(props: IWatermarkModelProps) {
  const {
    isOpen,
    onCloseModal,
    actions,
    getRevisionData,
    type,
    setCounter,
    swalalterHandlerAdd,
  } = props;

  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);

  const [flagFile, setFlagFile] = useState<any>(null);
  const {databaseName,revisionId} = useRouteParams<any>();
  const inputElRef = React.useRef<any>();
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
    // ----------------------------------------------

    const [countryList, setCountryList] = React.useState<any[]>([]);
    const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
    const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
    const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
      React.useState<any>("");
    // ----------------------------------------------

  var ErrorModalFiles = useBaseModal();
  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };
  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setFlagFile(e.target.files[0]);
    }
  };
  const swalMsgalterHandlerAdd = (type: any, msgtype: any, msg: any) => {
    if (type == true) {
      swalalterHandlerAdd(type, msgtype);
    } else {
      ErrorModalFiles.open();
      setErrorModalFileMsg(msg);
    }
  };
  React.useEffect(() => {
    API.get("/api/db/cost_databases/", {
      database_type: "labour",
    })
      .then((res: any) => {
        setCategoryList(res?.data);
      })
      .catch((err: any) => {});
  }, []);

  React.useEffect(() => {
    if (type == "edit") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes([actions?.currency_code]);
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("labour_type", actions?.labour_type);

      formik.setFieldValue("commodity", actions?.commodity);

      formik.setFieldValue("description", actions?.description);

      formik.setFieldValue("labour_code", actions?.labour_code);
      // formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);
    }
  }, [actions]);


  const formik = useFormik({
    initialValues: {
      labour_type: "",
      commodity: "",
      description: "",
      labour_code: "",
      reference_library: "",
      wage_per_day_library: "",
      perks_library: "",
      dearness_allowance_library: "",
      id: 0,
      unit: "Hour",
      currency_id: "",
      currency_code: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.currency_id = String(values.currency_id);
      if (type == "edit") {
        if (flagFile != null || flagFile != undefined) {
          LabourFlagS3Upload(
            [flagFile],
            values,
            setIsLoadingSave,
            setCounter,
            "",
            setFlagFile,
            actions?.id,
            "Edit",
            swalMsgalterHandlerAdd,
            onCloseModal
          );
        } else {
          setIsLoadingSave(true);
          API.put(`/api/db/labour/${actions?.id}/`, values, {}, 0)
            .then((res: any) => {
              setIsLoadingSave(false);
              setFlagFile(null);
              swalalterHandlerAdd(true, "edit");
              onCloseModal();
              setCounter((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              setIsLoadingSave(false);

              const { data } = err?.response;
              ErrorModalFiles.open();

              setErrorModalFileMsg(data[0]);
            });
        }
      } else {
        if (flagFile != null || flagFile != undefined) {
          if(!databaseName?.includes("DB")){
            LabourFlagS3UploadFetch(
              [flagFile],
            values,
            setIsLoadingSave,
            setCounter,
            formik,
            setFlagFile,
            null,
            "Create",
            swalMsgalterHandlerAdd,
            onCloseModal,
            revisionId
            )
          }else{
          LabourFlagS3Upload(
            [flagFile],
            values,
            setIsLoadingSave,
            setCounter,
            formik,
            setFlagFile,
            null,
            "Create",
            swalMsgalterHandlerAdd,
            onCloseModal
          );
          }
        } else {
          setIsLoadingSave(true);
          API.post(`/api/db/labour/`, values, {}, 0)
            .then((res: any) => {
              if (!databaseName?.includes("DB")) {
                API.post("/api/db/labour_cost/", {
                  commodity: values.commodity,
                  labour_ids: [res.data.id],
                  revision: revisionId,
                })
                  .then((res: any) => {
                    setIsLoadingSave(false);
                    setFlagFile(null);
                    setCounter((prev: any) => prev + 1);
                    onCloseModal();
                    formik.resetForm();
                  })
                  .catch((err: any) => {
                  });
              }else{
              setIsLoadingSave(false);
              setFlagFile(null);
              setCounter((prev: any) => prev + 1);
              onCloseModal();
              swalalterHandlerAdd(true, "create");
              formik.resetForm();
              }
            })
            .catch((err: any) => {
              setIsLoadingSave(false);

              const { data } = err?.response;
              ErrorModalFiles.open();

              setErrorModalFileMsg(data[0]);
            });
        }
      }

      {
      }
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});



  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {type == "edit" ? "Edit" : "Add"} Labour
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "1rem 2rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    label="Labour Code "
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="labour_code"
                    name="labour_code"
                    error={
                      formik.touched.labour_code &&
                      Boolean(formik.errors.labour_code)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.labour_code && formik.errors.labour_code
                    }
                    onChange={formik.handleChange}
                    value={formik.values.labour_code}
                    multiline
                  />
                  {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Labour Type"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="labour_type"
                    name="labour_type"
                    onChange={formik.handleChange}
                    value={formik.values.labour_type}
                    multiline
                    error={
                      formik.touched.labour_type &&
                      Boolean(formik.errors.labour_type)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.labour_type && formik.errors.labour_type
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Description"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Commodity
                  </InputLabel>
                  <Select
                    id="commodity"
                    name="commodity"
                    disabled={type == "edit" ? true : false}
                    //   className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.commodity &&
                      Boolean(formik.errors.commodity)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.commodity && formik.errors.commodity
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.commodity || ""}
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {CategoryList &&
                      CategoryList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.commodity &&
                      Boolean(formik.errors.commodity) && (
                        <p>{formik.errors.commodity}</p>
                      )}
                  </div>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <input
                    type="file"
                    // accept=".png, .jpg, .jpeg"
                    id="photo"
                    style={{ display: "none" }}
                    onChange={handleImg}
                    ref={inputElRef}
                    //  disabled={ type === "to_user" ? true : false }
                  />

                  <Button
                    onClick={browseFiles}

                    // autoFocus={autoFocusStatus}

                    //   multiline
                    //   error={formik.touched.flag && Boolean(formik.errors.flag)}
                    //   helperText={formik.touched.flag && formik.errors.flag}
                  >
                    Choose File {flagFile == null ? "" : flagFile?.name}
                  </Button>
                  <span></span>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      // onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  variant="contained"
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1,
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  loading={isLoadingsave}
                  type="submit"
                >
                  {type == "edit" ? "Save" : "Add"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddLabourModal;
