import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Divider,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Save, Delete } from "@mui/icons-material";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { number } from "yup";

function ConfigCustomValidation({
  input,
  isOpen,
  onCloseModal,
  calc_field,
  cost_config,
  refreshInputs,
}: any) {
  const [condition, setCondition] = useState("");
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [value, setValue] = useState("");
  const [isChanged, setIsChanged] = useState(false); // Track if there's a change

  const isSaveDisabled = !condition || value === "";

  const handleSave = () => {
    setLoader(true);
    API.post(
      `cost/field_custom_validation/`,
      {
        cost_config: cost_config,
        calc_field: calc_field,
        validation: { condition: condition, value: Number(value) },
      },
      {},
      0
    )
      .then((res: any) => {
        setLoader(false);

        refreshInputs();
      })
      .catch((err: any) => {
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
          customClass: {
            container: "swal2Container",
          },
        });
      })
      .finally(() => {
        setLoader(false);
      });
    // console.log({ parameterName, condition, value });
    // onCloseModal();
  };



  useEffect(() => {
    const hasChanged =
      condition !== input?.ValidationCondition ||
      value !== input?.ValidationValue;
    setIsChanged(hasChanged);
  }, [condition, value, input?.ValidationCondition, input?.ValidationValue]);

  const getValidation = () => {
    API.get(
      `cost/field_custom_validation/`,
      {
        cost_config: cost_config,
        calc_field: calc_field,
      },
      0
    )
      .then((res) => {
        if (res.data?.validation) {
            setValue(res.data?.validation?.value);
            setCondition(res.data?.validation?.condition)
        }
       
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getValidation();
  }, []);

  console.log(value, "value");
  console.log(condition, "condition");

  const handleDeleteValidation = () => {
    setDeleteLoader(true);
    API.delete(`cost/field_custom_validation/`,{
        cost_config: cost_config,
        calc_field: calc_field,
      }, 0)
        .then((res: any) => {
            setValue("");
            setCondition("");
            refreshInputs();
            onCloseModal()
        })
        .catch((err: any) => {
          
          console.log("Sever Error");
        }).finally(()=> {
setDeleteLoader(false)
        });
  }
  
  

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90vw", sm: "50vw", md: "40vw" }, // Adjusts width for different screens
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Custom Validation
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box display="flex" gap={2} sx={{ p: 2 }}>
          {/* Parameter Name with Tooltip */}
          <Tooltip title={input?.name || ""} placement="top" arrow>
            <TextField
              type="string"
              variant="standard"
              label="Parameter"
              value={input?.name}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "primary.main", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "primary.main", // Focused label color
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "primary.main", // Disabled label color
                },
                // marginTop: "1.4rem",
                width: "100%",
                "& .MuiInputBase-input": {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                },
              }}
              InputProps={{ inputProps: { min: 0 }, readOnly: true }}
            />
          </Tooltip>

          {/* Condition Dropdown */}
          <FormControl fullWidth>
            <InputLabel
              id="condition-label"
              sx={{
                color: "primary.main", // Set label text color
                "&.Mui-focused": { color: "primary.main" }, // Maintain color when focused
                "&.MuiInputLabel-shrink": { color: "primary.main" }, // Maintain color when shrinked
              }}
            >
              Condition
            </InputLabel>
            <Select
              labelId="condition-label"
              variant="standard"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              label="Condition"
            >
              <MenuItem value="lessThan">Less Than</MenuItem>
              <MenuItem value="lessThanEqual">Less Than Equal</MenuItem>
              <MenuItem value="greaterThan">Greater Than</MenuItem>
              <MenuItem value="greaterThanEqual">Greater Than Equal</MenuItem>
             
           
              <MenuItem value="equality">Equal</MenuItem>
            </Select>
          </FormControl>

          {/* Value Input */}
          <TextField
            type="number"
            label="Value"
            variant="standard"
            value={value}
            onChange={(e: any) =>
              e.target.valueAsNumber >= 0 && setValue(e.target.value)
            }
            InputProps={{ inputProps: { min: 0 } }}
            sx={{
              "& .MuiInputLabel-root": {
                color: "primary.main", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "primary.main", // Focused label color
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "primary.main", // Disabled label color
              },
            }}
          />

          {/* Action Buttons */}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ marginTop: "0.75rem" }}
          >
            {loader ? (
              <CircularProgress size="20px" />
            ) : (
              <IconButton
              color="primary"
                disabled={isSaveDisabled}
                // color={isChanged ? "error" : "primary"} // Change color based on state
                onClick={handleSave}
              >
                <Save />
              </IconButton>
            )}
            {deleteLoader ? <CircularProgress size="20px" /> :  <IconButton color="error" onClick={handleDeleteValidation}>
              <Delete sx={{
    color: "error.main", // Default color
    "&:hover": {
      color: "error.main", // Ensures hover color stays the same
    },
    "&:active": {
      color: "error.main", // Ensures active color stays the same
    },
    "&:focus": {
      color: "error.main", // Ensures focus color stays the same
    },
  }}  />
            </IconButton>}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfigCustomValidation;
