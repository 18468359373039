import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { RowData } from "./BOMRobotoflowFetchDataTable";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../../Redux/Services/admin.service";

export interface ICustomMultiSelectProps {
  tableDataTop: any;
  tableDataBottom: any;
  row: RowData;
  getTableData: any;
}

interface Option {
  label: string;
  value: string | number;
}

export function CustomMultiSelect(props: ICustomMultiSelectProps) {
  const { tableDataTop, tableDataBottom, row,getTableData } = props;
  const { vault } = useRouteParams<any>();

  const filterCapacitors = (data: RowData[]): RowData[] => {
    return data.filter(
      (item) => item.item_type === "Capacitor" && item.id !== row.id
    );
  };

  const [selectedValues, setSelectedValues] = useState<any>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(value);
  };

  const handleBlur = () => {
    if (selectedValues.length > 0) {
      API.get(
        `/xcpep/merge_pcb_data/${vault}/`,
        {
          
          merge_to_id : row.id,
          merge_from_id : selectedValues?.join(","),
        },
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Merged Successfully");
          getTableData()
        })
        .catch((err: any) => {});
    }

  };

  return (
    <Select
      multiple
      displayEmpty
      value={selectedValues}
      onChange={handleChange}
      onBlur={handleBlur}
      input={<OutlinedInput />}
      sx={{ width: "20rem" }}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <Typography color="text.secondary">Merge with</Typography>;
        }

        const firstValue = selected[0];
        const otherCount = selected.length - 1;

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip
              label={
                [...tableDataTop, ...tableDataBottom].find(
                  (opt) => opt.id === firstValue
                )?.label || firstValue
              }
            />
            {otherCount > 0 && <Chip label={`+${otherCount}`} />}
          </Box>
        );
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      {filterCapacitors([...tableDataTop, ...tableDataBottom]).map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.id}
        </MenuItem>
      ))}
    </Select>
  );
}
