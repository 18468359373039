import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import HistoryIcon from "@mui/icons-material/History";
import ClearIcon from "@mui/icons-material/Clear";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolderred from "../../Assets/images/svgs/openfolderred.svg";
import openFolderGreen from "../../Assets/images/svgs/openfoldergreen.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import calculatorred from "../../Assets/images/calculator_red-svg.svg";
import calculatorgreen from "../../Assets/images/calculator_green.svg";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import "@szhsin/react-menu/dist/index.css";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import AddProcessModal from "./AddProcessModal";
import { API } from "../../api-services";
import InfoTreeModal from "./InfoTreeModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { CalculatorPasteModal } from "./CalculatorPasteModal";
import Swal from "sweetalert2";
import FormulaModal from "./FormulaModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddBoughtOutRevisionModal } from "../../ComponentUI/CostingCreateModule/CostingEstimation/AddBoughtOutRevisionModal";
import InfoIcon from "@mui/icons-material/Info";
import { CostingDecimal } from "../../ComponentUI/CostingCreateModule/CostingEstimation/CreateCalculatorWithCategoryModal";


function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CostingTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  useraction?: any;
  getTreeData?: any;
  setOpenForm: any;
  costDetailsData: any;
  SelectedmulIds: any;
  AddBOPRefreshTree: any;
  type?: any;
  materialPercentage?: any; 
  processPercentage?: any; 
  overheadPercentage?: any; 
  childPercentage?: any;
}
const CreateCostingCategoryTreeNew = (
  props: CostingTreeViewcomponentsProps
) => {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableCounter,
    condition,
    calctype,
    scenarioId,
    costEstimationId,
    newUI,
  } = useRouteParams<any>();
  const {
    HierarchyData,
    onSelectCallback,
    setSelectedTreeData,
    setCounterApiCall,
    useraction,
    getTreeData,
    setOpenForm,
    costDetailsData,
    SelectedmulIds,
    AddBOPRefreshTree,
    type,
    materialPercentage, processPercentage, overheadPercentage, childPercentage,
  } = props;
  const history = useHistory<any>();
  const decimal = React.useContext(CostingDecimal);
  const AddBoughtOutModal = useBaseParamsModal();
  const { tableId, calcultorId, selectedIdTree } = useRouteParams<any>();
  const openFormula = useBaseParamsModal();
  const infoModal = useBaseModal();
  const costModelPaste = useBaseParamsModal();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const [rightClickedId, setRightClickedId] = React.useState<any>("");
  const inputElRef = React.useRef<any>();
  const [uploadCalcListLoader, setUploadCalcListLoader] =
    React.useState<any>(false);

  const open = Boolean(anchorEl);
  
  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([
    parseInt(selectedIdTree),
  ]);
  const [selected, setSelected] = React.useState<any>(selectedIdTree);

  const addProcess = useBaseParamsModal();

  const handleChange = (nodeId: any, id: any, data: any, itemId: any) => {
    setLoading(true);
    onSelectCallback(nodeId, id, data, itemId);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (data && data?.type === "calculator") {
      setSelectedTreeData(data);
      sessionStorage.setItem("calculator_type_create", data?.calculator_type);
    } else {
      setSelectedTreeData({});
      sessionStorage.removeItem("calculator_type_create");
    }
    let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(id)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != id;
      });
      let b: any = sessionStorage.setItem(
        "Costingconfigurationnodeconfig",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(id);
      // val.push(id);
      let b: any = sessionStorage.setItem(
        "Costingconfigurationnodeconfig",
        JSON.stringify(val)
      );
    }

    const expandedNodes = getAutoExpandedNodes(data);
    const index = expanded.indexOf(id);
    if (index === -1) {

      expandedNodes?.forEach((item: any) => {
        if (!val?.includes(item)) {
          val.push(item); // Add to arr2 if not present
        }
      });
    } else {


      expandedNodes?.forEach((item: any) => {
        const indexx = val?.indexOf(item);
        if (indexx !== -1) {
          val.splice(indexx, 1); // Add to arr2 if not present
        }
      });
    }

    sessionStorage.setItem(
      "Costingconfigurationnodeconfig",
      JSON.stringify(val)
    );

    setActiveId(id);

    //  const index = expanded.indexOf(id);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(id);
    } else {
      copyExpanded?.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  const getUrl = (type: any,id:any) => {
    switch (type) {
      case "material":
        return `/cost/material/${id}/`;
      case "process":
        return `/cost/process/${id}/`;
      case "overhead":
        return `/cost/overhead/${id}/`;
      default:
        return `/cost/material/${id}/`;
    }
  }

  const renderData = useMemo(() => {
    return (
      HierarchyAllData &&
      HierarchyAllData?.data?.map((subsystemData: any) => {
        return (
          <>
            <CreateCostingCategoryTreeNew
              HierarchyData={subsystemData}
              key={subsystemData?.sequence_id}
              useraction={useraction && useraction}
              setSelectedTreeData={setSelectedTreeData}
              onSelectCallback={onSelectCallback}
              setCounterApiCall={setCounterApiCall}
              getTreeData={getTreeData}
              setOpenForm={setOpenForm}
              costDetailsData={costDetailsData}
              SelectedmulIds={SelectedmulIds}
              AddBOPRefreshTree={AddBOPRefreshTree}
              type={type}
            />
          </>
        );
      })
    );
  }, [HierarchyAllData]);

  useEffect(() => {
    let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
    let val: any = JSON.parse(a) || [];


    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [
    selectedIdTree,
    sessionStorage.getItem("Costingconfigurationnodeconfig"),
  ]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);


  // Caluculate the cost percentage

  const uploadCalc = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("commodity", HierarchyAllData?.commodity_id);
    formData.append("process", HierarchyAllData?.id);
    setUploadCalcListLoader(true);
    API.post("/cost/upload_calculator_data/", formData, {}, 0)
      .then(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Success!",
          text: "Calculator uploaded successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Error in uploading calculator",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    uploadCalc(file);
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const handleDisableCalc = (calc: any) => {
    API.put(
      `/cost/calculation/${calc?.id}/`,
      {
        status: calc?.status === true ? false : true,
      },
      {},
      0
    )
      .then((res: any) => {
        getTreeData(type);
      })
      .catch((err: any) => { });
  };


  const getAutoExpandedNodes = (data: any, resultArr: any = []) => {
    const nodes = Array.isArray(data) ? data : [data]; // Wrap in array if not already an array

    nodes.forEach((node: any) => {
      // Check if node has exactly one child in its data array
      if (node?.data?.length === 1) {
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Recursively continue with its single child
        getAutoExpandedNodes(node?.data, resultArr);
      } else if (node?.data?.length > 1) {
        // If node has multiple children in data array
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Push sequence_id of each immediate child
        //node?.data.forEach((child:any) => resultArr.push(child?.sequence_id));
      }
    });

    return resultArr;
  };

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.sequence_id}
        id={HierarchyAllData?.sequence_id}
        defaultParentIcon={
          HierarchyAllData?.type !== "parent_type" &&
            HierarchyAllData?.type !== "child" ? (
            <FolderIcon
              sx={{ color: HierarchyAllData?.folder_status ? "green" : "red" }}
            />
          ) : (
            ""
          )
        }
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.type !== "calculator" && (
            <>
              {HierarchyAllData?.type !== "parent_type" &&
                HierarchyAllData?.type !== "child" ? (
                <img
                  src={
                    HierarchyAllData?.folder_status
                      ? openFolderGreen
                      : openFolderred
                  }
                  style={{
                    width: "18px",
                    color: HierarchyAllData?.folder_status ? "green" : "red",
                  }}
                />
              ) : (
                ""
              )}
            </>
          )
          // )
        }
        defaultExpandIcon={
          HierarchyAllData?.type !== "parent_type" &&
            HierarchyAllData?.type !== "child" ? (
            <FolderIcon
              sx={{ color: HierarchyAllData?.folder_status ? "green" : "red" }}
            />
          ) : (
            ""
          )
        }
        defaultEndIcon={
          HierarchyAllData?.type !== "parent_type" &&
            HierarchyAllData?.type !== "child" ? (
            HierarchyAllData?.type === "calculator" ? (
              <img
                src={
                  HierarchyAllData?.folder_status
                    ? calculatorgreen
                    : calculatorred
                }
                style={{
                  width: "13px",
                  color: HierarchyAllData?.folder_status ? "green" : "red",
                }}
              />
            ) : (
              <FolderIcon
                sx={{
                  color: HierarchyAllData?.folder_status ? "green" : "red",
                }}
              />
            )
          ) : (
            ""
          )
        }
        onNodeSelect={() => {
          if (HierarchyAllData?.type !== "parent_type") {
            if (HierarchyAllData?.type === "child") {
              history.push(
                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${HierarchyAllData?.id}/none/0/0/0/0/0/0/0/${scenarioId}/0/${newUI}`
              );
            } else {
              handleChange(
                HierarchyAllData?.name,
                HierarchyAllData?.sequence_id,
                HierarchyAllData,
                HierarchyAllData?.id
              );
            }
          }
          sessionStorage.removeItem("show_calidation");
        }}
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == selectedIdTree
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
        style={{ cursor: "context-menu" }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.sequence_id}
          nodeId={HierarchyAllData?.sequence_id}
          label={
            <Typography
              className={styles.treeViewData}
              sx={{
                backgroundColor:
                  HierarchyAllData?.calculator?.id === calcultorId &&
                    HierarchyAllData?.object_id == tableId
                    ? "#8080808f !important"
                    : "",
                color:
                  HierarchyAllData?.calculator?.id === calcultorId &&
                    HierarchyAllData?.object_id == tableId
                    ? "#000000 !important"
                    : "",
              }}
            >
              <div
                className={styles.treeViewText}
                style={
                  HierarchyAllData?.type === "calculator"
                    ? {
                      color:
                        HierarchyAllData?.calculator?.id === calcultorId &&
                          HierarchyAllData?.object_id == tableId
                          ? "#ffffff !important"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                      backgroundColor: HierarchyAllData?.color,
                    }
                    : {
                      color:
                        HierarchyAllData?.calculator?.id === calcultorId &&
                          HierarchyAllData?.object_id == tableId
                          ? "#ffffff !important"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                      backgroundColor: HierarchyAllData?.color,
                    }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "50%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1rem",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          // whiteSpace: "nowrap",
                          overflow: "hidden !important",
                          textOverflow: "ellipsis !important",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",
                          gap: "0.8rem",
                          color:
                            HierarchyAllData?.type === "calculator"
                              ? HierarchyAllData?.status
                                ? "inherit"
                                : "grey"
                              : "inherit",
                          textDecoration:
                            HierarchyAllData?.type === "child"
                              ? "underline"
                              : "inherit",
                        }}
                      >
                        {HierarchyAllData?.name}  {HierarchyAllData?.alias === "RM + BOP" ? `(${materialPercentage?.toFixed(2)}%)` : HierarchyAllData?.alias === 'Process Cost' ? `(${processPercentage?.toFixed(2)}%)` : HierarchyAllData?.alias === 'Overhead Cost' ? `(${overheadPercentage?.toFixed(2)}%)` : HierarchyAllData?.alias === 'Uint Cost' ? `(${childPercentage?.toFixed(2)}%)` : '' }
                        {HierarchyAllData?.name === "Bought-Out Part" && HierarchyAllData?.add_bop_button && (
                          <AddCircleIcon
                            sx={{ cursor: "pointer", fontSize: "17px" }}
                            onClick={(ed: any) => {
                              ed.stopPropagation();
                              ed.preventDefault();
                              AddBoughtOutModal.open(costDetailsData);
                            }}
                          />
                        )}
                        {(HierarchyAllData?.type === "material" || HierarchyAllData?.type === "process" || HierarchyAllData?.type === "overhead" ) && (
                          HierarchyAllData?.status?<ClearIcon
                            sx={{ cursor: "pointer", fontSize: "17px" ,color:'red'}}
                            onClick={(ed: any) => {
                              ed.stopPropagation();
                              ed.preventDefault();
                              API.put(`${getUrl(HierarchyAllData?.type,HierarchyAllData?.id)}`, { status: !HierarchyAllData?.status }, {}, 0).then((res:any)=>{
                                if (costDetailsData && costDetailsData?.cost_estimation) {
                                  getTreeData("header");
                                  getTreeData("material");
                                  getTreeData("process");
                                  getTreeData("overhead");
                                  if (costDetailsData?.is_leaf == false) {
                                    getTreeData("child_cost");
                                  }
                                }
                              }).catch((err:any)=>{})
                            }}
                          />:
                          <HistoryIcon
                            sx={{ cursor: "pointer", fontSize: "17px" ,color: "#007fff"}}
                            onClick={(ed: any) => {
                              ed.stopPropagation();
                              ed.preventDefault();
                              API.put(`${getUrl(HierarchyAllData?.type,HierarchyAllData?.id)}`, { status: !HierarchyAllData?.status }, {}, 0).then((res:any)=>{
                                if (costDetailsData && costDetailsData?.cost_estimation) {
                                  getTreeData("header");
                                  getTreeData("material");
                                  getTreeData("process");
                                  getTreeData("overhead");
                                  if (costDetailsData?.is_leaf == false) {
                                    getTreeData("child_cost");
                                  }
                                }
                              }).catch((err:any)=>{})
                            }}
                          />
                        )}

                      </Box>
                      {HierarchyAllData?.type === "calculator" && (
                        <>
                          {HierarchyAllData?.status === true ? (
                            <ClearIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDisableCalc(HierarchyAllData)
                              }
                            />
                          ) : (
                            <HistoryIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#007FFF",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDisableCalc(HierarchyAllData)
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "1.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0 0.2rem",
                          width: "10rem",
                          textTransform: "none",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          borderRight: "1px solid #007fff1f",
                          color:
                            HierarchyAllData?.type === "calculator"
                              ? HierarchyAllData?.status
                                ? "inherit"
                                : "grey"
                              : "inherit",
                        }}
                      >
                        {HierarchyAllData?.type === "calculator" ? (
                          <Tooltip
                            title={HierarchyAllData?.show_count || 0}
                            placement="left"
                          >
                            <InfoIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={HierarchyAllData?.show_count || 0}
                            placement="left"
                          >
                            <span>{HierarchyAllData?.show_count || 0}</span>
                          </Tooltip>
                        )}
                      </Box>

                      <Tooltip title={HierarchyAllData?.alias}>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0 0.2rem",
                            width: "10rem",
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            borderRight: "1px solid #007fff1f",
                            color:
                              HierarchyAllData?.type === "calculator"
                                ? HierarchyAllData?.status
                                  ? "inherit"
                                  : "grey"
                                : "inherit",
                          }}
                        >
                          {HierarchyAllData?.alias || "--"}
                        </Box>
                      </Tooltip>

                      {HierarchyAllData?.type === "calculator" ? (
                        <Tooltip title={HierarchyAllData?.result || ""}>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: "5rem",
                              textTransform: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                              color:
                                HierarchyAllData?.type === "calculator"
                                  ? HierarchyAllData?.status
                                    ? "inherit"
                                    : "grey"
                                  : "inherit",
                            }}
                          >
                            {/* {HierarchyAllData?.std_result ||
                              HierarchyAllData?.std_result === 0
                              ? HierarchyAllData?.std_result
                              : "--"} */}
                               {(decimal==0?Math.floor(parseFloat(HierarchyAllData?.result)):(parseFloat(HierarchyAllData?.result)?.toFixed(decimal || 2)) || HierarchyAllData?.result) ||
                              HierarchyAllData?.result === 0
                              ? (decimal==0?Math.floor(parseFloat(HierarchyAllData?.result)):(parseFloat(HierarchyAllData?.result)?.toFixed(decimal || 2)) || HierarchyAllData?.result)
                              : "--"}
                              
                          </Box>
                        </Tooltip>
                      ) : (
                        <Tooltip title={HierarchyAllData?.cost || ""}>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: "5rem",
                              textTransform: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {(decimal==0?Math.floor(+HierarchyAllData?.cost):parseFloat(HierarchyAllData?.cost)?.toFixed(decimal || 2)) ||
                              HierarchyAllData?.cost === 0
                              ? (decimal==0?Math.floor(+HierarchyAllData?.cost):parseFloat(HierarchyAllData?.cost)?.toFixed(decimal || 2))
                              : "--"}
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Box>
              </div>
            </Typography>
          }
          sx={{
            cursor:
              HierarchyAllData?.type === "parent_type"
                ? "default !important"
                : "pointer",
          }}
        >
          {loading
            ? HierarchyAllData?.type !== "calculator" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box>
            )
            : renderData}
        </StyledTreeItem>
      </TreeView>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".enc"
        id="file"
        name="file"
        ref={inputElRef}
        onChange={handleChangeFile}
        onClick={handleInputClick}
        capture="user"
      />
      {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={rightClickedId && rightClickedId}
        />
      )}
      {openFormula.isOpen && (
        <FormulaModal
          isOpen={openFormula.isOpen}
          onCloseModal={openFormula.close}
          data={openFormula.propsId}
          getTreeData={getTreeData}
        />
      )}

      {costModelPaste.isOpen && (
        <CalculatorPasteModal
          isOpen={costModelPaste.isOpen}
          onCloseModal={costModelPaste.close}
          HierarchyAllData={costModelPaste.propsId}
          setCounterApiCall={setCounterApiCall}
        />
      )}

      {AddBoughtOutModal.isOpen && (
        <AddBoughtOutRevisionModal
          isOpen={AddBoughtOutModal.isOpen}
          onCloseModal={AddBoughtOutModal.close}
          costDetailsData={AddBoughtOutModal.propsId}
          // getRMData={AddBOPRefreshTree}
          getRMData={() => getTreeData(type)}
          SelectedmulIds={SelectedmulIds}
        />
      )}
    </>
  );
};

export default CreateCostingCategoryTreeNew;
