import { API } from "../../api-services";
const url = "/customer/dashboard/"

const Url = "/auth/user_request/";
const PermDelete = "/auth/user_permission_request/";

export default {
    userData ( id: any, accepted: boolean, date?: any, user_type?: any, email_otp?:any,phone_otp?:any, send_welcome_mail?:any) {
        return API.post( Url, { id: id, accepted: accepted, date: date, user_type: user_type,email_otp:email_otp,phone_otp:phone_otp, send_welcome_mail:send_welcome_mail} );
    },

    getLandingPageLeftHeader (page_no:any) {
        return API.get( url ,{page_no:page_no},0);
    },
    getLandingPageRightCount (calculation:boolean) {
        return API.get( url, {calculation:calculation},0);
    },
    PermissionAccepted ( action: any, module: any, product: any, sub_module: any, id: any ) {
        return API.post( PermDelete, {
            action: action, module: module, product: product, sub_module: sub_module, id: id
        } );

    },
    PermissionDelete ( action: any, module: any, product: any, sub_module: any, id: any ) {
        return API.delete( PermDelete, {
            action
                : action, module: module, product: product, sub_module: sub_module, id: id
        } );

    },
};
