import { TextField } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useRouteMatch } from "react-router-dom";

export interface ICustomTextFieldProps {
  row: any;
  setUserInputUpdated?: any;
  inputData?: any;
  getData?: any;
  type?: any;
}

export function CustomTextField(props: ICustomTextFieldProps) {
  const { row, setUserInputUpdated, inputData, getData, type } = props;
  const { url } = useRouteMatch();
  const [userInput, setUserInput] = React.useState<number>(0);

  const ExactDebounce = React.useRef(
    debounce(async (id: any, value: any, material_cost: any) => {
      let uri =
        type === "Tool"
          ? "/api/db/tool_cost_entry/"
          : "/api/db/material_cost_entry/";
      await API.put(
        uri,
        {
          user_input: {
            [id]: value,
          },
          [type === "Tool" ? "tool_cost" : "material_cost"]:
            type === "Tool" ? inputData?.idd : inputData?.id,
        },
        {},
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Data Updated Successfully");
          getData();
        })
        .catch((err: any) => {
          ADMIN.toast.error("Data Updation Failed");
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    if (row && row.value) {
      setUserInput(row.value || 0);
      setUserInputUpdated(row.value || 0);
    }
  }, [row]);
  const handleInputChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value) || 0;
    setUserInput(newValue);
    setUserInputUpdated(newValue);
    ExactDebounce(id, newValue, inputData?.idd);
    // onValueChange(id, newValue);
  };
  return (
    <>
      {url.includes("createcostingpart") || url.includes("view") ? (
        userInput
      ) : (
        <TextField
          type="number"
          variant="standard"
          value={userInput ?? ""}
          onChange={(event: any) => handleInputChange(row.id, event)}
          size="small"
        />
      )}
    </>
  );
}
