import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Backdrop,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import RawMaterialS3Upload from "./RawMaterialS3Upload";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import RViewerJS from "viewerjs-react";
import calcImage from "../../Assets/images/calculator.svg";
import calcImageGreen from "../../Assets/images/calculator_green.svg";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import DatabaseCalculator from "../ParameterLibrary/DatabaseCalculator";
import SettingsIcon from "@mui/icons-material/Settings";
import DatabaseCalculatorConfig from "../ParameterLibrary/DatabasecalculatorConfig";
import RawMaterialFileUpload from "./RawMaterialFileUpload";
import Swal from "sweetalert2";
import { ADMIN } from "../../Redux/Services/admin.service";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  description: yup.mixed(),
  material_cost: yup.mixed(),
  material_source: yup.mixed(),
  material_reference: yup.mixed(),
  scrap_cost: yup.mixed(),
  scrap_source: yup.mixed(),
  scrap_reference: yup.mixed(),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
  currency_code: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  
}

function SubRevisionEditModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, actions, expanded, getMaterialData } = props;
  const openCal = useBaseParamsModal();
  const openConfig = useBaseParamsModal();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [resStatus, setResStatus] = React.useState<any>(0);
  const [actionCount, setActionCount] = React.useState<any>(0);
  const [file, setFile] = React.useState<any>(null);
  const [fileName, setFileName] = React.useState<any>(null);
  const [downloadLoading, setDownloadLoading] = React.useState<any>(false);
  const [uploadLoading, setUploadLoading] = React.useState<any>(false);
  const [isuserInput, setIsUserInput] = React.useState<boolean>(false);
  const classes = useStyles();
  const { categoryName } = useRouteParams<any>();
  const [isCal, setIsCal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (actions && actionCount === 0) {
      setIsUserInput(actions?.material_cost_is_user_input || false);
      setActionCount(actionCount+1)
    }
  }, [actions]);

  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");

  const [flag, setFlag] = React.useState<any>();
  const handleChange = (e: any) => {
    setFlag(e.currentTarget.files);
  };

  const handleChangefile = (e: any) => {
    setFile(e.currentTarget.files);
    setFileName(e.target.files[0]?.name);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserInput(event.target.checked);
  };
  React.useEffect(() => {
    if (actions) {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes([actions?.currency_code]);
      } else {
        setSelectedCurrencyCodes([]);
      }

      // ---------------------------------------------------
      formik.setFieldValue("description", actions.description);
      formik.setFieldValue("material_cost", actions?.material_cost);
      formik.setFieldValue("material_source", actions.material_source);
      formik.setFieldValue("material_reference", actions?.material_reference);
      formik.setFieldValue("scrap_cost", actions.scrap_cost);
      formik.setFieldValue("scrap_source", actions.scrap_source);
      formik.setFieldValue("scrap_reference", actions.scrap_reference);
      formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);
    }
  }, [actions]);
  const formik = useFormik({
    initialValues: {
      description: "",
      material_cost: "",
      material_source: "",
      material_reference: "",
      scrap_cost: "",
      scrap_source: "",
      scrap_reference: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);
      const { currency_code, ...apiPayload } = values;

      if (flag) {
        await RawMaterialS3Upload(
          actions.id,
          { ...values, material_cost_is_user_input: isuserInput },
          flag,
          setUploadLoading,
          "RMRevision",
          onCloseModal,
          getMaterialData,
          categoryName
        );
      } else {
        values.currency_id = String(values.currency_id);

        API.put(
          `/api/db/material_cost/${actions.id}/`,
          { ...apiPayload, material_cost_is_user_input: isuserInput },
          {},
          0
        )
          .then((res: any) => {
            setIsLoadingSave(false);
            getMaterialData(categoryName);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  let filename: string = actions?.material_file
    ?.split("/")
    .pop()
    .split(" ")
    .join("");

  const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file); // Append the file
  formData.append("material_id", actions?.id); // Append additional data
  formData.append("calc_type", "material"); // Append additional data

    API.post(
      `api/db/upload_db_calculator/`,
      formData,
      {},
      0
    )
      .then((res: any) => {
        setIsLoadingSave(false);
        getMaterialData(expanded);
        ADMIN.toast.info("File uploaded successfully");
        //onCloseModal();
      })
      .catch((err: any) => {
        setIsLoadingSave(false);
      });

    // await RawMaterialFileUpload(
    //   actions.material_id,
    //   { material_cost_is_user_input: isuserInput },
    //   file,
    //   setIsLoadingSave,
    //   "material",
    //   onCloseModal,
    //   getMaterialData,
    //   categoryName
    // );
  };

  const handleDownload = (ed: any) => {

   // console.log(actions);
    

    ed.stopPropagation();
    ed.preventDefault();
    setDownloadLoading(true);

    API.post(
      "api/db/download_db_calculator/",
      { calc_type: "material", material_id: actions?.id },
      {},
      0
    )
      .then((res: any) => {
        setDownloadLoading(false);
        window.open(res.data);
      })
      .catch((err: any) => {
        setDownloadLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Error in downloading file",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };


 

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {actions === "add" ? "Add" : "Edit"} Revision Raw Material
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Remarks"
                    disabled
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.25rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "24rem",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "1rem", whiteSpace: "nowrap", mr: 0.8 }}
                      >
                        Raw material Cost :{" "}
                      </Typography>
                      <img
                        src={
                          actions?.result && actions?.result?.length > 0
                            ? calcImageGreen
                            : calcImage
                        }
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          opacity: !isuserInput ? 1 : 0.5,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!isuserInput) {
                            openConfig.open();
                          }
                        }}
                      />
                      <SettingsIcon
                        sx={{
                          cursor: "pointer",
                          // color: resStatus && resStatus > 0 ? "green" : "red",
                          color:
                            ((actions?.result && actions?.result?.length > 0) || (actions?.material_result && actions?.material_result?.length > 0))
                              ? "green"
                              : "red",
                          opacity: !isuserInput ? 1 : 0.5,
                          mr: 0.8,
                        }}
                        onClick={() => {
                          if (!isuserInput) {
                            openCal.open();
                          }
                        }}
                      />
                      {(!actions?.material_calc || actions?.material_calc == undefined || actions?.material_calc == null) && (
                        <label htmlFor="file-upload">
                          {uploadLoading ? (
                            <CircularProgress
                              size={"15px"}
                              sx={{ marginTop: "0.5rem" }}
                            />
                          ) : (
                            <UploadIcon
                              sx={{ cursor: "pointer", marginTop: "0.5rem" }}
                            />
                          )}
                        </label>
                      )}

                      {(actions?.material_calc !== undefined && actions?.material_calc !== null) && (
                        <>
                          {downloadLoading ? (
                            <CircularProgress
                              size={"15px"}
                              sx={{ marginTop: "0.5rem" }}
                            />
                          ) : (
                            <DownloadIcon
                              sx={{ cursor: "pointer", marginTop: "0.5rem" }}
                              onClick={(e: any) => handleDownload(e)}
                            />
                          )}
                        </>
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        alignSelf: "center",
                        fontSize: "1.5rem",
                        // mr: "5rem"
                        // marginTop: "1.85rem",
                      }}
                    >
                      /
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        onChange={handleCheckboxChange}
                        checked={isuserInput}
                      />
                      {/* <a>Enter cost manually</a> */}
                    </Box>
                    <FormControl
                      fullWidth
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="Enter manually cost"
                        // autoFocus={autoFocusStatus}
                        InputProps={{
                          style: { color: "#007fff" },
                        }}
                        disabled={!isuserInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "primary.main",
                          },
                          "& .MuiFormLabel-root.MuiInputLabel-root ": {
                            color: "primary.main",
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        id="material_cost"
                        name="material_cost"
                        onChange={formik.handleChange}
                        value={formik.values.material_cost}
                        multiline
                        error={
                          formik.touched.material_cost &&
                          Boolean(formik.errors.material_cost)
                        }
                        helperText={
                          formik.touched.material_cost &&
                          formik.errors.material_cost
                        }
                      />

                      {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                    </FormControl>
                  </Box>
                  {/* <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      fontSize: "0.87rem",
                      marginLeft: "-1rem",
                      position: "relative",
                      textAlign: "left",
                    }}
                  >
                    File
                  </InputLabel>
                  <Input
                    type="file"
                    onChange={handleChangefile}
                    // value={filename}
                  />
                </FormControl> */}

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <input
                      id="file-upload"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e: any) =>
                        uploadFile(e.currentTarget.files[0])
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_source"
                    name="material_source"
                    onChange={formik.handleChange}
                    value={formik.values.material_source}
                    multiline
                    error={
                      formik.touched.material_source &&
                      Boolean(formik.errors.material_source)
                    }
                    helperText={
                      formik.touched.material_source &&
                      formik.errors.material_source
                    }
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_reference"
                    name="material_reference"
                    onChange={formik.handleChange}
                    value={formik.values.material_reference}
                    multiline
                    error={
                      formik.touched.material_reference &&
                      Boolean(formik.errors.material_reference)
                    }
                    helperText={
                      formik.touched.material_reference &&
                      formik.errors.material_reference
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Cost"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_cost"
                    name="scrap_cost"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_cost}
                    multiline
                    error={
                      formik.touched.scrap_cost &&
                      Boolean(formik.errors.scrap_cost)
                    }
                    helperText={
                      formik.touched.scrap_cost && formik.errors.scrap_cost
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_source"
                    name="scrap_source"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_source}
                    multiline
                    error={
                      formik.touched.scrap_source &&
                      Boolean(formik.errors.scrap_source)
                    }
                    helperText={
                      formik.touched.scrap_source && formik.errors.scrap_source
                    }
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      // onChange={(event: any) => {
                      //   setSelectedCurrencyCodes(event.target.value);
                      //   const selectedCurIds = countryList
                      //     .filter((item: any) => event.target.value === item?.currency_code)
                      //     .map((obj: any) => obj?.id);

                      //   formik.setFieldValue("currency_id", selectedCurIds);
                      // }}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);
                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);

                        // Convert array of selectedCurIds into a string
                        formik.setFieldValue(
                          "currency_id",
                          selectedCurIds.join(",")
                        );
                        formik.setFieldValue(
                          "currency_code",
                          String(event.target.value)
                        );
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_code &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_code &&
                        formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  columnGap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_reference"
                    name="scrap_reference"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_reference}
                    multiline
                    error={
                      formik.touched.scrap_reference &&
                      Boolean(formik.errors.scrap_reference)
                    }
                    helperText={
                      formik.touched.scrap_reference &&
                      formik.errors.scrap_reference
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      fontSize: "0.87rem",
                      marginLeft: "-1rem",
                      position: "relative",
                      textAlign: "left",
                    }}
                  >
                    Flag
                  </InputLabel>
                  <Input
                    type="file"
                    onChange={handleChange}
                    // value={filename}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>Previous uploaded file - </Typography>
                {actions?.material_file && (
                  <div>
                    {actions?.material_file
                      ?.split("/")
                      .pop()
                      ?.split(".")
                      .pop() == "pdf" ? (
                      <Box
                        sx={{ borderBottom: "1px solid #007dff" }}
                        onClick={() => {
                          window.open(actions?.material_file, "_blank");
                        }}
                      >
                        {actions?.material_file?.split("/").pop()}
                      </Box>
                    ) : (
                      // @ts-ignore
                      <RViewerJS>
                        <Image
                          src={actions?.material_file}
                          placeholderImg={LoadingImage}
                          title={actions?.material_file?.split("/").pop()}
                          style={{
                            width: "60%",
                            height: "6rem",
                            objectFit: "contain",
                            marginLeft: "1rem",
                            cursor: "pointer",
                            alignSelf: "center",
                          }}
                          alt={actions?.material_file?.split("/").pop()}
                          key={actions?.material_file?.split("/").pop()}
                        />
                      </RViewerJS>
                    )}
                  </div>
                )}
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      {openCal.isOpen && (
        <DatabaseCalculator
          isOpen={openCal.isOpen}
          onCloseModal={openCal.close}
          id={actions.id}
          inputData={actions}
          setCounter={getMaterialData}
          parentClose={onCloseModal}
          categoryName={categoryName}
        />
      )}
      {openConfig.isOpen && (
        <DatabaseCalculatorConfig
          isOpen={openConfig.isOpen}
          onCloseModal={openConfig.close}
          id={actions.id}
          inputData={actions}
          setCounter={getMaterialData}
          parentClose={onCloseModal}
          categoryName={categoryName}
          setResStatus={setResStatus}
          typee={"material"}
        />
      )}
    </div>
  );
}
export default SubRevisionEditModal;
