import {  Typography } from "@mui/material";
import * as React from "react";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";

import PCBExpandPopupModalView from "../PCBExpandPopupModalView";
import BPMPCBRoboflowModal from "./BOMPCBRobodlowModal";

export interface IBOMEntryAccordianProps {
  tableName: any;
  item: any;
  getTableData?: any;
  materialCount?: any;
  BOMValidationStatus?:any;
  tableAccData?:any;
  tableIdddList?:any;
  setTableDataCount?:any;
  validateCount?:any;
}

export function BOMEntryAccordian(props: IBOMEntryAccordianProps) {
  const {
    tableName,
    item,
    getTableData,
    materialCount,
    BOMValidationStatus,
    tableAccData,
    tableIdddList,
    setTableDataCount,
    validateCount
  } = props;

const roboFlowModal = useBaseParamsModal();
  const BOMPCBModal = useBaseParamsModal();
  const BOMPCBModalHandler = () => {
    BOMPCBModal.open();

  };
 
  return (
    <>
      {BOMPCBModal.isOpen && (
        <PCBExpandPopupModalView
          isOpen={BOMPCBModal.isOpen}
          onCloseModal={BOMPCBModal.close}
          expandStatus={false}
          idddd={item}
          tableName={tableName}
          getTableData={getTableData}
          BOMValidationStatus={BOMValidationStatus}
          tableAccData={tableAccData}
          tableIdddList={tableIdddList}
          setTableDataCount={setTableDataCount}
        />
      )}
        {roboFlowModal.isOpen && (
              <BPMPCBRoboflowModal
                onCloseModal={roboFlowModal.close}
                isOpen={roboFlowModal.isOpen}
                tableId={item}
              />
            )}
      <Typography onClick={() => BOMPCBModalHandler()} sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>{tableName} ({materialCount})</Typography>
        {tableName == "PCB BOM Table" && (
          <>
          <Typography onClick={() => BOMPCBModalHandler()} sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>Costing Pending ({materialCount - validateCount?.validated_count})</Typography>
          <Typography onClick={() => BOMPCBModalHandler()} sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>Costing Completed ({validateCount?.validated_count})</Typography>
          <Typography onClick={(e:any) =>{
            e.stopPropagation();
            e.preventDefault();
            roboFlowModal.open(item)
          }} sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>Fetch Data</Typography>
          </>
        )}
        <ZoomOutMapIcon
          titleAccess="Expand PCB Table"
          sx={{ cursor: "pointer" }}
          onClick={() => BOMPCBModalHandler()}
        />
     
    </>
  );
}
